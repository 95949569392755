<template>
  <component :is="layout">
    <slot/>
  </component>
</template>
<script setup>
import {markRaw, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import DefaultLayout from './DefaultLayout.vue';
import NavigationLayout from './NavigationLayout'
import DarkLayout from "@/views/layouts/DarkLayout.vue";

const layout = ref(null);
const route = useRoute();

watch(() => route.meta,
    async meta => {
        if (meta.layout === 'NavigationLayout') {
            layout.value = markRaw(NavigationLayout)
        } else if (meta.layout === 'DarkLayout') {
            layout.value = markRaw(DarkLayout)
        } else {
            layout.value = markRaw(DefaultLayout)
        }
    },
    {immediate: true}
)

</script>
