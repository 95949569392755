import {api, coordinator, routes} from "@/plugins/axios";
import {ref} from "vue";

export function useOrganizations() {
    const fetching = ref(false);

    const getOrganizationsByName = async (name) => {
        fetching.value = true;
        return coordinator.get(routes.organizations.search(name))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .finally(() => {
                fetching.value = false;
            });
    }

    const getOrganizationBySlug = async (slug) => {
        fetching.value = true;
        return coordinator.get(routes.organizations.get(slug))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .finally(() => {
                fetching.value = false
            });
    }

    const getOrganizationsExcept = async (uids) => {
        fetching.value = true;
        return coordinator.get(routes.organizations.getExcept(uids))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .finally(() => {
                fetching.value = false
            });
    }
    
    const updateOrganizationInfo = (organizationSlug, data) => {
        return api.post(routes.organizations.updateInfo(organizationSlug), {
            name: data.name,
            color: data.color
        }).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    const uploadLogo = (organizationSlug, logo) => {
        const formData = new FormData();
        formData.append('logo', logo);

        return api.post(routes.organizations.uploadLogo(organizationSlug), formData).catch((err) => {
            return Promise.reject(err);
        });
    }

    const uploadCover = (organizationSlug, cover) => {
        const formData = new FormData();
        formData.append('cover', cover);

        return api.post(routes.organizations.uploadCover(organizationSlug), formData).catch((err) => {
            return Promise.reject(err);
        });
    }

    const deleteLogo = (organizationSlug) => {
        return api.delete(routes.organizations.deleteLogo(organizationSlug)).catch((err) => {
            return Promise.reject(err);
        });
    }

    const deleteCover = (organizationSlug) => {
        return api.delete(routes.organizations.deleteCover(organizationSlug)).catch((err) => {
            return Promise.reject(err);
        });
    }

    const updateOrganization = (organizationSlug, data) => {
        return coordinator.patch(routes.organizations.update(organizationSlug), data)
    }

    return {
        getOrganizationsByName,
        getOrganizationBySlug,
        getOrganizationsExcept,
        updateOrganizationInfo,
        uploadCover,
        uploadLogo,
        deleteCover,
        deleteLogo,
    }
}
