<template>
    <div class="flex items-center">
        <input :id="randomID" type="checkbox" :value="value" v-model="checked" :disabled="isDisabled"
               :class="[checked && 'hover:opacity-50', !isDisabled && 'hover:border-primary-1 ','focus:ring-0 focus:ring-transparent h-4 w-4 border-interactive-6 rounded text-primary-1']"/>
        <label v-if="showLabel" class="block text-body-6 font-normal text-default-1 ml-2" :for="randomID">
            <span :class="labelClass" v-if="label"> {{ label }}  </span>
            <span :class="labelClass" v-if="!label">  {{ value }} </span>
        </label>
    </div>
</template>
<script setup>
import {computed} from 'vue';

const props = defineProps({
    label: {
        type: String
    },
    labelClass: {
        type: [String, Array]
    },
    modelValue: {
        type: Boolean
    },
    value: {
        type: String
    },
    isDisabled: {
        type: Boolean
    },
    showLabel: {
        type: Boolean,
        default: true
    }
});
const emit = defineEmits(['update:modelValue']);

const checked = computed({
    get: () => {
        return props.modelValue
    },
    set: (value) => {
        emit('update:modelValue', value);
    }
});

const randomID = computed(() => {
    return Math.random().toString(36).substring(2, 9);
});

</script>
