<template>
  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
            :stroke-dasharray="circlesDashArray"
            class="base-timer__path-remaining"
            :style="[colorMode === 'dark' ? 'stroke: ' + darkPrimaryColor : 'stroke:' + themePrimaryColor]"
            d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span class="base-timer__label" :class="styleClasses.timeLabel">{{ timeLeft }}</span>
  </div>
</template>

<script setup>
import {computed, onMounted, watch} from "vue";
import {useCountdown} from "@/stores/CountdownStore";
import {storeToRefs} from "pinia";
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig)

const darkPrimaryColor = fullConfig.theme.colors.dark.primary[1];
const themePrimaryColor = fullConfig.theme.colors.dark.primary[1];

const store = useCountdown();
const {timeLeft, circlesDashArray} = storeToRefs(store);
const {startTimer} = store;

const emit = defineEmits(['end', 'change']);

const props = defineProps({
    time: {
        type: Number,
        default: 30
    },
    colorMode: {
        type: String,
        default: 'light',
    }
});

const styleClasses = computed(() => ({
    timeLabel: {
        'text-default-1': props.colorMode === 'light',
        'text-dark-default-10': props.colorMode === 'dark',
    }
}));

onMounted(() => startTimer(props.time));

watch(timeLeft, (value) => {
    emit('change', value);
    if (value === 0) {
        emit('end');
    }
});

</script>
