export default {
	methods: {
		/**
		 * Gets SVG avatar
		 */
		getAvatar() {
			let avatar;
			if (this.conversation.conversationType === "user") {
				avatar = this.conversation.conversationWith.avatar;
			} else if (this.conversation.conversationType === "group") {
				avatar = this.conversation.conversationWith.icon;
			}
			return avatar;
		},
	}
}