<template>
    <Multiselect
        :classes="classes"
        v-bind="$attrs"
    >
        <template
            v-for="(_, name) in $slots"
            v-slot:[name]="slotData"
        >
            <slot :name="name" v-bind="slotData"/>
        </template>
    </Multiselect>
</template>

<script setup>
import Multiselect from '@vueform/multiselect';
import {ref} from "vue";

const classes = ref({
    container: 'multiselect text-body-6 text-default-1 placeholder:text-default-4 bg-white border h-10 w-full rounded text-left focus:outline-none focus:ring-transparent focus:border-primary-1',
    search: 'w-full h-10 absolute border-0 inset-0 outline-none focus:ring-0 appearance-none box-border text-default-1 text-body-6 bg-transparent rounded pl-3.5 rtl:pl-0 rtl:pr-3.5 hover:border-primary-1 hover:border',
    option: 'flex items-center justify-start box-border text-left cursor-pointer leading-snug pl-4 pr-2 py-2 focus:text-primary-1 text-body-6 text-default-1',
    optionPointed: 'hover:text-primary-1 hover:bg-background-3',
    optionSelected: 'bg-background-3',
    optionDisabled: 'text-default-4 cursor-not-allowed',
    optionSelectedPointed: 'bg-background-3 opacity-90',
    optionSelectedDisabled: 'bg-background-3 opacity-70 cursor-not-allowed pointer-events-none',
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.multiselect {
    border-color:#EFF2F7;
    &.is-active {
        border: 1px solid var(--primary-color);
        box-shadow: none;
    }

    &.is-disabled {
        background: white;
        .multiselect-caret {
            display: none;
        }
    }
}

.multiselect-dropdown {
    max-height: 400px;
}

.multiselect-caret, .multiselect-clear-icon {
    z-index: 1;
}

.white-inset {
    -webkit-box-shadow: inset 0px 0px 0px 10px #FFFFFF;
    box-shadow: inset 0px 0px 0px 10px #FFFFFF;
}

.multiselect-inifite-spinner, .multiselect-spinner {
    background-color: var(--primary-color);
}
</style>
