import {api, routes} from "@/plugins/axios";

export function useNotifications() {
    const getNotifications = (organizationSlug, memberUid, page = 1, perPage = 10, search = '', types = []) => {
        return api.get(routes.members.notifications.get(organizationSlug, memberUid, page, perPage, search, types)).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    const getNotificationsTypes = (organizationSlug) => {
        return api.get(routes.members.notifications.types(organizationSlug)).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    const readNotification = async(organizationSlug, memberUid, notificationUid) => {
        return api.post(routes.members.notifications.read(organizationSlug, memberUid, notificationUid)).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    const deleteNotification = async(organizationSlug, memberUid, notificationUid) => {
        return api.delete(routes.members.notifications.delete(organizationSlug, memberUid, notificationUid)).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }

    const storeFcmToken = async (organizationSlug, memberUid, fcmToken) => {
        return api.post(routes.members.pushNotifications.store(organizationSlug, memberUid), {
            "fcm_token": fcmToken
        });
    }

    const deleteFcmToken = async (organizationSlug, memberUid, tokenUid) => {
        return api.delete(routes.members.pushNotifications.delete(organizationSlug, memberUid, tokenUid));
    }

    return {
        getNotifications,
        getNotificationsTypes,
        readNotification,
        deleteNotification,
        storeFcmToken,
        deleteFcmToken
    }
}
