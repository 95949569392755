import axios from "axios";
import routes from "./routes";
import {cookieStorage} from "@/pinia/cookieStorage";
import useTimezone from "@/composables/useTimezone";

const error_invalid_token = 'invalid_token';
const error_profile_partially_completed = 'profile_partially_completed';
const error_member_not_active = 'member_not_active';
const error_onboarding_not_completed = 'onboarding_in_progress';

const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    interceptors: {
        response: [
            response => response,
            error => {
                if (error.response === undefined) {
                    return Promise.reject(error);
                }

                if (error.response.status === 401 || error.response.data.reason === error_invalid_token) {
                    cookieStorage.removeItem('auth');
                    cookieStorage.removeItem('user-uid');
                    window.location.pathname = '/login';
                }

                if (error.response.data.reason === error_profile_partially_completed) {
                    window.location.pathname = '/complete-profile-reminder';
                }

                if (error.response.data.reason === error_onboarding_not_completed) {
                    window.location.pathname = '/onboarding-reminder';
                }

                if (error.response.data.reason === error_member_not_active) {
                    window.location.pathname = '/access-denied';
                }

                if (error.response.data.errors) {
                    for (const field in error.response.data.errors) {
                        error.response.data.errors[field] = error.response.data.errors[field][0];
                    }
                }

                return Promise.reject(error);
            }
        ],
        request: [
            config => {
                const auth = JSON.parse(cookieStorage.getItem('auth'));

                if (auth && auth.token !== null) {
                    config.headers.Authorization = `Bearer ${auth.token}`;
                }

                const {getClientTimezone} = useTimezone();

                config.headers['X-Client-Timezone'] = getClientTimezone();

                return config;
            },
        ]
    },
}

const coordinator = axios.create({
    baseURL: window.appConfig.COORDINATOR_API_URL,
    ...config
});

const api = axios.create({
    baseURL: window.appConfig.ORG_API_URL,
    ...config
});

api.interceptors.response.use(...config.interceptors.response);
coordinator.interceptors.response.use(...config.interceptors.response);
api.interceptors.request.use(...config.interceptors.request);
coordinator.interceptors.request.use(...config.interceptors.request);

export {coordinator, api, routes};
