<template>
    <Image v-if="hasAvatar" :src="handleAvatar" :lazyLoading="false" type="profile" class="inline-block" :class="[handleSize, handleShape, handleWhiteOutline]" no-delay />
    <div
        v-else
        class="flex items-center justify-center bg-semitone-primary-1 text-body-7 text-default-1"
        :class="[handleSize, handleShape, handleWhiteOutline]"
    >
        {{ nameInitial }}
        <p v-if="lastName">
            {{ lastName.charAt(0).toUpperCase() }}
        </p>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useAuth} from "@/stores/AuthStore";
import {useImagePathFormatter} from "@/composables/useImagePathFormatter";
import {storeToRefs} from "pinia";
import Image from "@/components/custom/Image.vue";

const {organization} = storeToRefs(useAuth());

const props = defineProps({
    avatar: {
        type: [String, null],
        required: true
    },
    firstName: {
        type: String,
        required: true,
        default: 'A'
    },
    lastName: {
        type: String,
        required: false,
    },
    size: {
        type: String,
        default: 'small'
    },
    shape: {
        type: String,
        default: 'rounded'
    },
    hasWhiteOutline: {
        type: Boolean,
        default: false
    },
})

const hasAvatar = computed(() => {
    return props.avatar !== null;
});

const handleAvatar = computed(() => {
    return useImagePathFormatter(organization.value.slug, props.avatar)
})

const nameInitial = computed(() => {
    return props.firstName.charAt(0).toUpperCase();
})

const handleSize = computed(() => {
    const sizes = {
        small: 'h-6 w-6',
        large: 'h-14 w-14'
    }
    return sizes[props.size];
})

const handleShape = computed(() => {
    const shapes = {
        rounded: 'rounded-full',
        square: 'rounded-[4px]'
    }
    return shapes[props.shape];
})

const handleWhiteOutline = computed(() => {
    return props.hasWhiteOutline ? 'ring-4 ring-white' : '';
})
</script>