<template>
    <button type="button" :class="buttonClasses" :disabled="disabled"
            class="relative rounded focus:outline-none font-normal min-w-[80px] flex justify-center items-center">
        <slot/>
        <span>
            {{ label }}
        </span>
        <LoadingCircle v-if="loading"/>
        <div v-if="buttonType !== 'outline-white'" class="absolute hover:opacity-5 hover:bg-black inset-0"></div>
    </button>
</template>

<script setup>
import {computed} from "vue";
import LoadingCircle from "../icons/LoadingCircle.vue";
import {useTheme} from "@/stores/ThemeStore";

const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    colorMode: {
        type: String,
        default: 'light',
    },
    buttonType: {
        type: String,
        validator: function (value) {
            return ['primary', 'secondary', 'ghost', 'text', 'outline-white', 'action-primary', 'action-secondary', 'highlighted-secondary', 'ghost-shade'].indexOf(value) !== -1;
        },
        default: 'primary'
    },
    size: {
        type: String,
        default: 'default',
        validator: function (value) {
            return ['default', 'small'].indexOf(value) !== -1;
        }
    },
});

const {textColorClass} = useTheme();

const hoverTextColorClass = 'hover:' + textColorClass;

const buttonClasses = computed(() => {
    return {
        'opacity-50 cursor-not-allowed': props.disabled,
        'bg-primary-1': (props.buttonType === 'primary' || props.buttonType === 'action-primary') && props.colorMode === 'light',
        'bg-dark-primary-1 text-white': (props.buttonType === 'primary' || props.buttonType === 'action-primary') && props.colorMode === 'dark',
        [textColorClass]: (props.buttonType === 'primary' || props.buttonType === 'outline-white' || props.buttonType === 'action-primary') && props.colorMode !== 'dark',
        'bg-white border border-primary-1 text-primary-1': props.buttonType === 'secondary' || props.buttonType === 'action-secondary',
        'bg-white border border-primary-1 text-primary-1 hover:bg-primary-1' : props.buttonType === 'highlighted-secondary',
        [hoverTextColorClass]: props.buttonType === 'highlighted-secondary' && !props.disabled,
        'border border-white': props.buttonType === 'outline-white',
        'bg-interactive-9': props.buttonType === 'ghost' && props.colorMode === 'light',
        'bg-interactive-9 text-black': props.buttonType === 'ghost' && props.colorMode === 'dark',
        'bg-interactive-11': props.buttonType === 'ghost-shade' && props.colorMode === 'light',
        'bg-interactive-11 text-black': props.buttonType === 'ghost-shade' && props.colorMode === 'dark',
        'text-primary-1': props.buttonType === 'text' && props.colorMode === 'light',
        'text-slate-50': props.buttonType === 'text' && props.colorMode === 'dark',
        'text-body-6 text-normal py-2.5 px-4': props.size === 'default',
        'text-body-7 text-medium py-1 px-2': props.size === 'small',
        'rounded-3xl': props.rounded,
        'w-fit h-12 text-body-5': props.buttonType === 'action-primary' || props.buttonType === 'action-secondary',
    }
});
</script>
