import {useCheckFeature} from "@/composables/useCheckFeature";
import {storeToRefs} from "pinia";
import {useAuth} from "@/stores/AuthStore";
const {getMemberFeatures} = useCheckFeature();


export const checkFeatureGuard = async (to, from, next) => {
    const { organization } = storeToRefs(useAuth());

    const features = await getMemberFeatures(organization.value.slug);

    if(features) {
        if(Object.hasOwn(features, to.name)) {
            if(features[to.name]) {
                return next();
            }
            return next('feature-unavailable');
        }
    }
    return next();
};
