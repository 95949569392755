<template>
    <p class="text-body-3 text-default-1 font-medium mb-6">Selection of organization</p>

    <label class="block text-body-6 font-medium text-default-1 mb-1">
        Organization Name
    </label>

    <Multiselect
        @select="handleOnSelect"
        @clear="handleOnClear"
        :filter-results="false"
        :min-chars="0"
        :resolve-on-load="true"
        :delay="500"
        :searchable="true"
        :options="searchOrganizationsByName"
        trackBy="uid"
        valueProp="uid"
        label="name"
        placeholder="Enter or select your organization name"
    >
        <template v-slot:singlelabel="{ value }">
            <div class="multiselect-single-label">
                <img class="h-4 mr-1" :src="require('@/assets/icons/logo/blue/vertical.svg')"/>
                <span class="capitalize">{{ value.name }}</span>
            </div>
        </template>

        <template v-slot:option="{ option }">
            <img class="cursor-pointer h-4 mr-1" :src="require('@/assets/icons/logo/blue/vertical.svg')"/>
            <span class="cursor-pointer select-none relative -mb-px capitalize pl-2.5">{{ option.name }}</span>
        </template>
    </Multiselect>

    <div class="my-10">
        <CoreButton @click.prevent="handleSignUp" :disabled="!selectedOrganization">Sign Up</CoreButton>
    </div>
</template>

<script setup>
import Multiselect from '@/components/core/Multiselect';
import CoreButton from '@/components/core/Button.vue';
import {useSignUp} from "@/stores/SignUpStore";
import {useAuth} from "@/stores/AuthStore";
import {useVerifyPhoneNumber} from "@/stores/VerifyPhoneNumberStore";
import {useSubdomain} from "@/composables/useSubdomain";
import {useOrganizations} from "@/composables/useOrganizations";
import {ref} from "vue";

const signUpStore = useSignUp();
const verifyPhoneNumber = useVerifyPhoneNumber();
const {init} = useAuth();
const {getOrganizationsByName} = useOrganizations();
const selectedOrganization = ref(null);

const searchOrganizationsByName = async (search) => {
    return getOrganizationsByName(search).then((response) => {
        return response.data;
    });
}

const handleOnSelect = (uid, organization) => {
    selectedOrganization.value = organization;
}

const handleOnClear = () => {
    selectedOrganization.value = null;
}

const handleSignUp = () => {
    const {changeSubdomain} = useSubdomain();

    signUpStore.createMembership(selectedOrganization.value.uid)
        .then(() => {
            init(selectedOrganization.value.slug).then(() => {
                changeSubdomain(selectedOrganization.value.slug, '/onboarding');
            });
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
            verifyPhoneNumber.reset();
        });
}
</script>
