import { storeToRefs } from 'pinia';
import {useAuth} from "@/stores/AuthStore";

export const activeMemberGuard = (to, from, next) => {
    const { member } = storeToRefs(useAuth());

    if (member.value !== null && member.value.status === 'approved') {
        return next();
    }

    return next('access-denied');
};
