import {useCookies} from "@vueuse/integrations/useCookies";
import {createPersistedState} from "pinia-plugin-persistedstate";

const cookie = useCookies(null, {doNotParse: true});

export const cookieStorage = {
    getItem(key) {
        return cookie.get(key) ?? null
    },
    setItem(key, value) {
        cookie.set(key, value, {
            path: '/',
            domain: window.appConfig.DOMAIN,
        });
    },
    removeItem(key) {
        cookie.remove(key, {
            path: '/',
            domain: window.appConfig.DOMAIN,
        });
    }
}

const cookieStoragePlugin = createPersistedState({
    key: key => key,
    storage: cookieStorage,
    serializer: {
        serialize: (value) => {
            return JSON.stringify(value)
        },
        deserialize(value) {
            return JSON.parse(value);
        }
    }
});

export default cookieStoragePlugin;
