<template>
    <div class="p-6">
        <div class="mx-auto flex items-center justify-center">
            <VerifyPhoneIcon/>
        </div>

        <div class="m-2 flex flex-col justify-center">
            <p class="text-body-5 text-default-1 mb-8"> The phone number <span class="font-medium"> {{ phoneNumber }} </span> is already connected to an account on our app.<br></p>
            <p class="text-body-5 text-default-1">
                If this is your number, login to access your account. You will be able to request access to this organization once you are logged in.
            </p>

        </div>

        <div class="flex justify-center mt-14">
            <CoreButton @click.prevent="goToLogin">Log in</CoreButton>
            <CoreButton button-type="secondary" @click.prevent="emit('close')" class="ml-4">Back to register</CoreButton>
        </div>
    </div>

</template>

<script setup>
import CoreButton from '@/components/core/Button.vue';
import VerifyPhoneIcon from '@/components/icons/phone/Verify.vue';
import {useRouter} from "vue-router";

const props = defineProps({
    phoneNumber: {
        required: true,
        type: String
    }
});

const router = useRouter();
const emit = defineEmits(['close']);

function goToLogin() {
    router.push({name: 'login', query: {phoneNumber: props.phoneNumber}});
}

</script>
