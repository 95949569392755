<template>
    <svg width="150" height="150" viewBox="0 0 104 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="52" cy="50" r="50" fill="white"/>
        <circle opacity="0.1" cx="52" cy="50" r="50" :fill="color.hex"/>
        <g opacity="0.3" filter="url(#filter0_f_2310_401428)">
            <rect x="26.5977" y="30.5205" width="62.1953" height="32.7212" rx="4.66526" :fill="color.hex"/>
            <rect x="15.2031" y="46.0254" width="63.5868" height="33.4533" rx="4.76964" :fill="color.hex"/>
        </g>
        <rect x="26.5977" y="25.5205" width="62.1953" height="32.7212" rx="4.66526" fill="white"/>
        <rect opacity="0.1" x="26.5977" y="25.5205" width="62.1953" height="32.7212" rx="4.66526" :fill="color.hex"/>
        <rect opacity="0.3" x="34.3281" y="37.3018" width="20.48" height="2.35683" rx="0.777543" :fill="color.hex"/>
        <rect opacity="0.15" x="34.3281" y="43.4785" width="40.7974" height="2.35683" rx="0.777543" :fill="color.hex"/>
        <rect x="15.2031" y="41.0254" width="63.5868" height="33.4533" rx="4.76964" fill="white"/>
        <rect opacity="0.3" x="23.1016" y="54.0928" width="20.9382" height="2.40956" rx="0.794939" :fill="color.hex"/>
        <rect opacity="0.15" x="23.1016" y="60.4072" width="41.7102" height="2.40956" rx="0.794939" :fill="color.hex"/>
        <defs>
            <filter id="filter0_f_2310_401428" x="0.203125" y="15.5205" width="103.59" height="78.958" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="7.5" result="effect1_foregroundBlur_2310_401428"/>
            </filter>
        </defs>
    </svg>

</template>

<script setup>
import {useTheme} from "@/stores/ThemeStore";
const {color} = useTheme();
</script>