<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-[999999]" @close="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-semitone-modal-overlay transition-opacity"/>
            </TransitionChild>
            <div class="fixed z-[100] inset-0 overflow-y-auto">
                <div class="flex xxs:items-center justify-center min-h-full p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                                class="relative bg-white rounded px-4 pt-5 pb-4 text-left shadow-xl transform transition-all xxs:w-full  sm:max-w-lg sm:w-full bottom-1/4 sm:bottom-1/2 box-border"
                                :class="{ 'overflow-hidden' : overflowHidden }"
                        >
                            <div class="block absolute top-0 right-0 pt-4 pr-4 z-10">
                                <button type="button" @click.prevent="emit('close')">
                                    <span class="sr-only"> Close </span>
                                    <img src="../../assets/icons/close-dark.svg" />
                                </button>
                            </div>
                            <slot/>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
<script setup>
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {defineEmits, defineProps} from 'vue';

const props = defineProps({
    show: {
        type: Boolean,
        required: true,
        default: false,
    },
    overflowHidden: {
        type: Boolean,
        default: true,
    },
    ignoreClickOutside: {
        type: Boolean,
        default: true,
    },
});
const emit = defineEmits(['update:show', 'close']);

const close = () => {
    if(props.ignoreClickOutside) return;
    emit('update:show', false);
};
</script>
