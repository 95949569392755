import {useSubdomain} from "@/composables/useSubdomain";

export const organizationSubdomainGuard = (to, from, next) => {
    const {getSubdomain} = useSubdomain();

    const subdomain = getSubdomain();

    if (subdomain) {
        return next();
    }

    return next('/launchpad');
};
