import {defineStore} from 'pinia'
import {nextTick} from "vue";

export const useCountdown = defineStore('useCountdown', {
    state: () => ({
        fullDashArray: 283,
        countdownFrom: 30,
        timePassed: 0,
        timerInterval: null
    }),
    getters: {
        timeLeft() {
            return this.countdownFrom - this.timePassed;
        },
        timeFraction() {
            const rawTimeFraction = this.timeLeft / this.countdownFrom;

            return rawTimeFraction - (1 / this.countdownFrom) * (1 - rawTimeFraction);
        },
        countdownFinished() {
            return this.timePassed == this.countdownFrom;
        },
        circlesDashArray() {
            return `${(this.timeFraction * this.fullDashArray).toFixed(0)} 283`
        }
    },
    actions: {
        startTimer(time) {
            if (!this.timerInterval) {
                if (time) {
                    this.countdownFrom = time;
                }

                this.timePassed = 0;

                nextTick(() => {
                    this.timerInterval = setInterval(() => {
                        this.timePassed += 1

                        if (this.countdownFinished) {
                            clearInterval(this.timerInterval);
                            this.timerInterval = null;
                        }
                    }, 1000);
                })
            }
        },
        resetTimer() {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
            this.timePassed = 0;
        }
    },
})
