import {computed, ref} from "vue";
import {api, routes} from "@/plugins/axios";

const memberFeatures = ref(null);
const loading = ref(false);

export function useCheckFeature() {
    const getMemberFeatures = (organizationSlug) => {
        return api.get(routes.members.features(organizationSlug))
            .then((response) => {
                return Promise.resolve(response.data);
            }).catch((err) => {
                return Promise.reject(err);
            });
    }

    const loadMemberFeatures = (slug) => {
        if(loading.value) {
            return;
        }

        loading.value = true;

        return getMemberFeatures(slug).then((response) => {
            memberFeatures.value = response;
            return Promise.resolve(response);
        }).finally(() => {
            loading.value = false;
        });
    }

    const isChatFeatureActive = computed(() => {
        if(!memberFeatures.value) {
            return false;
        }
        return Object.hasOwn(memberFeatures.value, 'chat') && memberFeatures.value.chat;
    });

    return {
        memberFeatures,
        getMemberFeatures,
        loadMemberFeatures,
        isChatFeatureActive
    }
}