import { auth, guest, requestAccessMiddleware } from "@/middleware/auth";
import { createRouter, createWebHistory } from "vue-router";

import {
    activeMemberGuard,
    completedProfileGuard,
    onboardingCompleteGuard,
    onboardingIncompleteGuard,
    organizationSubdomainGuard,
    partiallyCompletedProfileGuard,
    requestAccess,
    themeSettingsGuard,
    adminOrRepresentativeGuard,
} from "@/guards";
import {checkFeatureGuard} from "@/guards/checkFeatureGuard";

const ProfileBuilder = () => import(/* webpackPrefetch: true */ "@/views/admin/members/ProfileBuilder.vue");
const Import = () => import(/* webpackPrefetch: true */'@/views/admin/members/Import.vue');
const ImportMembers = () => import(/* webpackPrefetch: true */'@/views/ImportMembers.vue');
const InviteUser = () => import(/* webpackPrefetch: true */'@/views/admin/members/InviteUser');
const ReviewMember = () => import(/* webpackPrefetch: true */'@/views/admin/members/ReviewMember.vue');
const Groups = () => import(/* webpackPrefetch: true */'@/views/dashboard/Groups');
const CreateGroup = () => import(/* webpackPrefetch: true */'@/views/admin/groups/Create.vue');
const EditGroup = () => import(/* webpackPrefetch: true */'@/views/admin/groups/Edit.vue');
const CreateSms = () => import(/* webpackPrefetch: true */'@/views/admin/sms/Create.vue');
const CreateEmail = () => import(/* webpackPrefetch: true */'@/views/admin/email/Create.vue');
const Inbox = () => import(/* webpackPrefetch: true */'@/views/admin/inbox/Inbox.vue');
const Login = () => import(/* webpackPrefetch: true */'@/views/login/Login.vue');
const RequestAccess = () => import(/* webpackPrefetch: true */'@/views/RequestAccess.vue');
const Register = () => import(/* webpackPrefetch: true */'@/views/register/Register.vue');
const Invitation = () => import(/* webpackPrefetch: true */'@/views/invitation/Invitation.vue');
const MemberProfile = () => import(/* webpackPrefetch: true */'@/views/dashboard/profile/MemberProfile.vue');
const Onboarding = () => import(/* webpackPrefetch: true */'@/views/dashboard/Onboarding');
const OnboardingReminder = () => import(/* webpackPrefetch: true */'@/views/guards/OnboardingReminder.vue');
const CompleteProfileReminder = () => import(/* webpackPrefetch: true */'@/views/guards/CompleteProfileReminder.vue');
const Notifications = () => import(/* webpackPrefetch: true */'@/views/dashboard/Notifications');
const Chat = () => import(/* webpackPrefetch: true */'@/views/chat/Chat.vue');
const Launchpad = () => import(/* webpackPrefetch: true */'@/views/dashboard/Launchpad.vue');
const Dashboard = () => import(/* webpackPrefetch: true */'@/views/dashboard/Dashboard.vue');
const CompleteOrganizationInfo = () => import(/* webpackPrefetch: true */'@/views/dashboard/profile/CompleteOrganizationInfo.vue');
const MembersList = () => import(/* webpackPrefetch: true */'@/views/dashboard/MembersList.vue');
const AccessDenied = () => import(/* webpackPrefetch: true */'@/views/guards/AccessDenied.vue');
const FeatureUnavailable = () => import(/* webpackPrefetch: true */'@/views/guards/FeatureUnavailable.vue');
const ThemeSettings = () => import(/* webpackPrefetch: true */'@/views/dashboard/ThemeSettings.vue');
const NotFound = () => import(/* webpackPrefetch: true */'@/views/NotFound.vue');
const CreateNews = () => import(/* webpackPrefetch: true */'@/views/news/CreateNews.vue');
const News = () => import(/* webpackPrefetch: true */'@/views/news/NewsList.vue');
const NewsArticle = () => import(/* webpackPrefetch: true */'@/views/news/NewsArticle.vue');
const PublicNews = () => import(/* webpackPrefetch: true */'@/views/news/Public/PublicNews.vue');
const Resources = () => import(/* webpackPrefetch: true */'@/views/admin/resources/Resources.vue');
const CreateExternalLink = () => import(/* webpackPrefetch: true */'@/views/resources/CreateWeblink.vue');
const CreatePage = () => import(/* webpackPrefetch: true */'@/views/resources/CreatePage.vue');
const ResourcesList = () => import(/* webpackPrefetch: true */'@/views/admin/resources/List.vue');
const PublicArticle = () => import(/* webpackPrefetch: true */'@/views/news/Public/PublicArticle.vue');
const PageResource = () => import('@/views/resources/PageResource.vue');
const ReorderDashboard = () => import('@/views/dashboard/ReorderDashboard.vue');
const DocumentsList = () => import(/* webpackPrefetch: true */'@/views/documents/List.vue');

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/theme-settings',
        name: 'theme-settings',
        component: ThemeSettings,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        },
        props: (route) => ({showModal: route.params.showModal}),
    },
    {
        path: '/launchpad',
        name: 'launchpad',
        component: Launchpad,
        meta: {
            middleware: [auth],
        }
    },
    {
        path: '/onboarding-reminder',
        name: 'onboarding-reminder',
        component: OnboardingReminder,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingIncompleteGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/complete-profile-reminder',
        name: 'complete-profile-reminder',
        component: CompleteProfileReminder,
        beforeEnter: [
            requestAccess,
            partiallyCompletedProfileGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: AccessDenied,
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/organization-info',
        name: 'organization-info',
        component: CompleteOrganizationInfo,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            partiallyCompletedProfileGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/view-profile/:uid',
        name: 'view-member-profile',
        component: MemberProfile,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        },
        props: (route) => ({uid: route.params.uid}),
    },
    {
        path: '/profile',
        name: 'profile',
        component: MemberProfile,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        },
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
            checkFeatureGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: Onboarding,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingIncompleteGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/groups',
        name: 'groups',
        component: Groups,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/members',
        name: 'members',
        component: MembersList,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
            adminOrRepresentativeGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [guest],
            layout: 'DarkLayout'
        }
    },
    {
        path: '/request-access',
        name: 'request-access',
        component: RequestAccess,
        meta: {
            middleware: [requestAccessMiddleware]
        }
    },
    {
        path: '/feature-unavailable',
        name: 'feature-unavailable',
        component: FeatureUnavailable,
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/invitation/:uid',
        name: 'invitation',
        component: Invitation,
        meta: {
            middleware: [guest]
        }
    },
    {
        path: '/profile-builder',
        name: 'profile-builder',
        component: ProfileBuilder,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/groups/create',
        name: 'create-group',
        component: CreateGroup,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/groups/edit/:uid',
        name: 'edit-group',
        component: EditGroup,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/import',
        name: 'import',
        component: Import,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/import-members',
        name: 'import-members',
        component: ImportMembers,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/review-member/:uid',
        name: 'review-member',
        component: ReviewMember,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        },
        props: (route) => ({uid: route.params.uid || ''}),
    },
    {
        path: '/invite-user',
        name: 'invite-user',
        component: InviteUser,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/sms/create',
        name: 'create-sms',
        component: CreateSms,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/sms/edit/:uid',
        name: 'edit-sms',
        component: CreateSms,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/email/create',
        name: 'create-email',
        component: CreateEmail,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/email/edit/:uid',
        name: 'edit-email',
        component: CreateEmail,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: Inbox,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        },
        props: (route) => ({activeSection: route.params.activeSection || '', activeItem:  route.query.activeItem ? JSON.parse(route.query.activeItem) : null}),
    },
    {
        path:'/news/create',
        name: 'create-news',
        component: CreateNews,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/news/edit/:uid',
        name: 'edit-news',
        component: CreateNews,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/news',
        name: 'news',
        component: News,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/news/:uid',
        name: 'news-article',
        component: NewsArticle,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/:catchAll(.*)',
        component: NotFound,
    },
    {
        path:'/public-news',
        name: 'public-news',
        component: PublicNews,
    },
    {
        path:'/public-news/:uid',
        name: 'public-article',
        component: PublicArticle,
    },
    {
        path: '/resources/create',
        name: 'create-resources',
        component: Resources,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/resources',
        name: 'resources',
        component: ResourcesList,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/resources/view/:uid',
        name: 'view-resource',
        component: PageResource,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: "/resources/create/external-link",
        name: "create-external-link",
        component: CreateExternalLink,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
        ],
        meta: {
            middleware: [auth],
            layout: "NavigationLayout",
        },
    },
    {
        path: '/resources/create/page',
        name: 'create-resource-page',
        component: CreatePage,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout',
        },
    },
    {
        path:'/resources/edit/external-link/:uid',
        name: 'edit-external-link',
        component: CreateExternalLink,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path:'/resources/edit/page/:uid',
        name: 'edit-page',
        component: CreatePage,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/reorder-dashboard',
        name: 'reorder-dashboard',
        component: ReorderDashboard,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard,
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
    {
        path: '/documents',
        name: 'documents',
        component: DocumentsList,
        beforeEnter: [
            requestAccess,
            organizationSubdomainGuard,
            onboardingCompleteGuard,
            completedProfileGuard,
            activeMemberGuard,
            themeSettingsGuard
        ],
        meta: {
            middleware: [auth],
            layout: 'NavigationLayout'
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
    }
    return middleware[0]({
        ...context
    })
});

export default router;
