import {api, routes} from "@/plugins/axios";
import {ref} from "vue";

export function useMemberFields() {
    const fetching = ref(false);

    const getMemberFields = async (organizationSlug, memberUid) => {
        return api.get(routes.members.fields.get(organizationSlug, memberUid))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            })
            .finally(() => {
                fetching.value = false;
            });
    }

    const updateMemberFields = async (organizationSlug, memberUid, fields) => {
        return api.patch(routes.members.fields.update(organizationSlug, memberUid), fields)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                return Promise.reject(error);
            })
            .finally(() => {
                fetching.value = false;
            });
    }

    return {
        getMemberFields,
        updateMemberFields,
        fetching
    }
}
