import {defineStore} from "pinia";
import {cookieStorage} from "@/pinia/cookieStorage";
import {ref} from "vue";

export const useLocale = defineStore('useLocale', function (){
    const phoneNumberRegion = ref(null);

    const setPhoneNumberRegion = (region) => {
        phoneNumberRegion.value = region;
    }

    const hasPhoneNumberRegion = () => {
        return phoneNumberRegion.value !== null;
    }

    return {phoneNumberRegion, setPhoneNumberRegion, hasPhoneNumberRegion}
}, {
    persist: [
        {
            key: 'locale',
            storage: cookieStorage,
            paths: ['phoneNumberRegion'],
            debug: true,
        },
    ]
})
