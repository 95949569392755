import { ref } from 'vue'

export function useSubdomain() {
    const hostname = ref(window.location.hostname);
    const subdomain = ref(null);
    const host = ref(null);
    const topLevelDomain = ref(null);

    const urlParts = window.location.hostname.split('.');

    if (urlParts.length === 2) {
        subdomain.value = null;
        host.value = urlParts[0];
        topLevelDomain.value = urlParts[1];
    }

    if (urlParts.length === 3) {
        subdomain.value = !['www', 'app'].includes(urlParts[0]) ? urlParts[0] : null;
        host.value = urlParts[1];
        topLevelDomain.value = urlParts[2];
    }

    const getSubdomain = () => {
        return subdomain.value;
    }

    const getHost = () => {
        return host.value;
    }

    const getTopLevelDomain = () => {
        return topLevelDomain.value;
    }

    const changeSubdomain = (subdomain, path = null) => {
        window.location.href = window.location.protocol + '//' + subdomain + '.' + host.value + '.' + topLevelDomain.value + path ?? window.location.pathname;
    }

    return {
        getSubdomain,
        getHost,
        getTopLevelDomain,
        changeSubdomain,
        hostname
    }
}
