import {defineStore} from "pinia";
import axios from "axios";
import {computed, reactive, ref} from "vue";
import {useColorContrastRatio} from "@/composables/colorContrastRatio";

export const useTheme = defineStore('useTheme', () => {
    const color = reactive({
        hex: '#556EE6',
        rgb: 'rgb(85, 110, 230)'
    });
    const cover = ref(null);
    const logo = ref(null);

    const {getContrastingColor} = useColorContrastRatio();

    document.body.style.setProperty('--primary-color', color.hex);

    const getColor = computed(() => {
        return color;
    });

    const getAuxiliaryColor = computed(() => {
        let aux = color.rgb.split('(');
        aux = aux[1].split(')');
        return `rgba(${aux[0]}, .2)`;
    });

    const textColor = computed(() => {
        return getContrastingColor(color.rgb);
    });

    const textColorClass = computed(() => {
        return textColor.value === 'white' ? 'text-white' : 'text-default-1';
    });

    function getTheme(slug) {
        if (!slug) {
            return;
        }

        axios.get(`${window.appConfig.COORDINATOR_API_URL}/api/organizations/${slug}/theme-settings`)
            .then(response => {
                document.body.style.setProperty('--primary-color', response.data.color.hex);
                color.hex = response.data.color.hex;
                color.rgb = response.data.color.rgb;
                cover.value = response.data.cover;
                logo.value = response.data.logo;
            });
    }

    function setTheme(theme) {
        document.body.style.setProperty('--primary-color', theme.color.hex);
        color.hex = theme.color.hex;
        color.rgb = theme.color.rgb;
        cover.value = theme.cover;
        logo.value = theme.logo;
    }
    
    return {getColor, getAuxiliaryColor, getTheme, getContrastingColor, setTheme, color, logo, cover, textColorClass, textColor}
});
