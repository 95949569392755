<template>
  <div class="sticky top-0 z-[100] flex-shrink-0 flex h-16 shadow">
    <button type="button"
      class="px-4 border-r border-interactive-9 text-default-9 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-transparent md:hidden bg-interactive-9"
      @click="emit('toggleSidebar')">
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
    </button>
    <div class="flex-1 px-1 md:px-4 flex justify-between items-center bg-white">
      <div class="ml-auto flex items-center gap-1 sm:gap-2 xl:gap-6 xxs:pr-3 mlg:pr-0">
        <div class="flex flex-row items-center">
          <ChatNotifications layout="dropdown" class="mt-1" v-if="isChatFeatureActive"/>
          <DropdownNotificationMenu notificationType="app" class="mt-1" v-if="showSystemNotifications"/>
        </div>
        <ProfileMenu/>
      </div>
    </div>
  </div>
</template>
<script setup>
import {MenuAlt2Icon} from '@heroicons/vue/outline'
import ProfileMenu from '../../components/menus/Profile.vue';
import DropdownNotificationMenu from '../../components/menus/DropdownNotification.vue';
import ChatNotifications from "@/components/custom-cometchat/ChatNotifications.vue";
import {useCheckFeature} from "@/composables/useCheckFeature";
import {useAuth} from "@/stores/AuthStore";
const {organization} = useAuth();

const {isChatFeatureActive} = useCheckFeature(organization.slug);

const showSystemNotifications = false;

const emit = defineEmits(['toggleSidebar']);
</script>
