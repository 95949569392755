import {defineStore, storeToRefs} from 'pinia';
import {useAuth} from "@/stores/AuthStore";
import {reactive, ref} from "vue";
import {useMembers} from "@/composables/useMembers";

export const useOnboarding = defineStore('useOnboarding', () => {
    const {organization, member} = storeToRefs(useAuth());
    const {
        updateMember, sendPhoneCode, sendEmailCode,
        checkPhoneCode, checkEmailCode, createPhoneNumber,
        createEmail
    } = useMembers();

    const loading = ref(false);
    const profilePicture = ref(null);
    const currentStep = ref(0);

    const errors = reactive({
        first_name: null,
        last_name: null,
        middle_name: null,
        phone_number: null,
        email: null,
        code: null,
        new_phone_number: null,
        new_email: null,
        work_status: null,
    });

    function updateMemberInfo(memberData) {
        return updateMember(organization.value.slug, member.value.uid, memberData).then(() => {
            member.value.first_name = memberData.firstName;
            member.value.last_name = memberData.lastName;
            currentStep.value++;
        }).catch((error) => {
            const {
                response: {
                    data: {
                        errors: {
                            first_name: firstName = false,
                            last_name: lastName = false
                        } = {}
                    } = {}
                } = {}
            } = error

            errors.first_name = firstName
            errors.last_name = lastName
        });
    }

    function sendPhoneVerificationCode(phoneNumber) {
        return sendPhoneCode(organization.value.slug, member.value.uid, phoneNumber)
            .then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                errors.phone_number = error.response.data.message;
            });
    }

    function checkPhoneVerificationCode(code, phoneNumber) {
        return checkPhoneCode(organization.value.slug, member.value.uid, code, phoneNumber)
            .then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                errors.code = error.response.data.message;
                return Promise.reject(error);
            })
    }

    function sendEmailVerificationCode(email) {
        return sendEmailCode(organization.value.slug, member.value.uid, email)
            .then((response) => {
                return Promise.resolve(response);
            }).catch((error) => {
                errors.email = error.response.data.message;
            });
    }

    function checkEmailVerificationCode(code, email) {
        return checkEmailCode(organization.value.slug, member.value.uid, code, email)
            .then((response) => {
                member.value.email = email;
                return Promise.resolve(response);
            }).catch((error) => {
                this.errors.code = error.response.data.message;
                return Promise.reject(error);
            })
    }

    function createMemberPhoneNumber(phoneNumber) {
        return createPhoneNumber(organization.value.slug, member.value.uid, phoneNumber)
            .then((response) => {
                let exists = member.value.phone_numbers.find(phone => phone.phone_number === response.data.phone_number);

                if (!exists) {
                    member.value.phone_numbers.push(response.data);
                }

                return Promise.resolve(response);
            }).catch((error) => {
                errors.new_phone_number = error.response.data.message;
                return Promise.reject(error);
            })
    }

    function createMemberEmail(email) {
        return createEmail(organization.value.slug, member.value.uid, email)
            .then((response) => {
                let exists = member.value.emails.find(email => email.email === response.data.email);

                if (!exists) {
                    member.value.emails.push(response.data.email);
                }

                return Promise.resolve(response.data.email);
            }).catch((error) => {
                errors.new_email = error.response.data.message;
                return Promise.reject(error);
            })
    }

    function resetCodeRelatedErrors() {
        errors.email = null;
        errors.code = null;
        errors.phone_number = null;
    }

    function resetAllErrors() {
        for (let key in this.errors) {
            errors[key] = null;
        }
    }

    return {
        loading,
        profilePicture,
        currentStep,
        errors,
        updateMemberInfo,
        sendPhoneVerificationCode,
        checkPhoneVerificationCode,
        sendEmailVerificationCode,
        checkEmailVerificationCode,
        createMemberPhoneNumber,
        createMemberEmail,
        resetCodeRelatedErrors,
        resetAllErrors,
    }
}, {
    persist: [
        {
            key: 'onboarding',
            storage: localStorage,
            paths: ['currentStep'],
            debug: true,
        },
    ]
});
