import { storeToRefs } from 'pinia';
import {useAuth} from "@/stores/AuthStore";

export const adminOrRepresentativeGuard = (to, from, next) => {
    const { isAdmin, isRepresentative } = storeToRefs(useAuth());

    if (isAdmin.value || isRepresentative.value) {
        return next();
    }

    return next('');
};
