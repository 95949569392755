<template>
    <div class="relative overflow-hidden" :class="[aspectRatio[type], roundness]">
        <transition name="fade">
            <div
                v-if="!isImageLoaded"
                class="absolute top-0 left-0 z-10 w-full skeleton-loader !rounded-b-none"
                :class="aspectRatio[type]">
            </div>
        </transition>
        <img
            class="w-full rounded-t"
            :src="src"
            @load="handleImageLoaded"
            :loading="lazyLoading"
            alt=""
        />
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    lazyLoading: {
        type: Boolean,
        default: true
    },
    type: {
        type: String,
        default: 'profile'
    },
    index: {
        type: Number,
        default: 1
    },
    noDelay: {
        type: Boolean,
        default: false
    },
    roundness: {
        type: String,
        default: ''
    }
})

const isImageLoaded = ref(false)

function handleImageLoaded() {
    if(props.noDelay) {
        return isImageLoaded.value = true
    }

    const timeoutVal = props.index * 150
    setTimeout(() => {
        isImageLoaded.value = true
    }, timeoutVal)
}

const lazyLoading = computed(() => {
    return props.lazyLoading ? 'lazy' : 'eager'
})

const aspectRatio = computed(() => ({
    profile: {
        'aspect-profile rounded-full': props.type === 'profile',
    },
    cover: {
        'aspect-cover rounded-t': props.type === 'cover',
    },
    thumbnail: {
        'aspect-thumbnail rounded-t': props.type === 'thumbnail',
    },
}))


</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>