<template>
    <Menu as="div" class="relative inline-block text-left ">
        <div>
            <MenuButton
                @click="handleOnClick"
                class="inline-flex justify-center w-full  bg-white">
                <ProfileNotification
                    :notificationCount="totalNotifications"
                    :notificationType="notificationType"
                    :ping="hasNewNotifications"
                />
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="open" ref="notificationsList">
                <MenuItems static
                           class="origin-top-right absolute xxs:left-1/2 lg:left-auto xxs:right-auto xxs:translate-x-[-48%] xs:translate-x-[-58%] lg:translate-x-0 lg:right-0 mt-2 xxs:w-[310px] xs:w-[380px] lg:w-[418px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="flex px-6 pt-5 pb-4 items-center">
                        <p class="text-body-5 text-default-1 font-medium">System Notifications </p>
                        <div v-show="totalNotifications > 0" @click="markAllAsRead" v-if="notifications.length > 0"
                             class="flex flex-center text-body-7 text-primary-1 font-normal ml-auto cursor-pointer">
                            <LoadingCircle v-if="markAllLoader" :color="color.hex" class="w-4 h-4 mr-2" />
                            <DoubleCheck :color="color.hex" class="mr-2"/>
                            Mark all as read
                        </div>
                    </div>
                    <div class="border-none pb-2">
                        <div
                            v-if="notifications.length > 0"
                            class="w-full mt-2 max-h-[500px] overflow-y-scroll px-3 mb-2 relative box-border"
                            :class="[notifications.length > 7 ? '' : 'no-scrollbar']"
                        >
                            <div v-if="notifications.length > 7"
                                 class="white-gradient z-10 sticky top-[95%] w-full h-9 -mt-9"></div>
                            <NotificationItem
                                v-for="notification in notifications"
                                :notification="notification"
                                :key="notification.uid"
                                @click="handleNotificationClick(notification)"
                                is-in-notification-bar
                            />
                        </div>
                        <div v-else class="w-full h-[400px] flex justify-center items-center flex-col">
                            <Notifications :color="color.hex"/>
                            <p class="mt-6 text-body-5">You do not have any notifications</p>
                        </div>
                        <router-link
                            to="/notifications"
                            class="text-body-6 text-primary-1 font-medium flex justify-center bg-background-3 mx-3 rounded h-8 items-center"
                            @click.prevent="goToViewAll"
                        >
                            View All
                        </router-link>
                    </div>
                </MenuItems>
            </div>
        </transition>
    </Menu>
</template>
<script setup>
import ProfileNotification from '../icons/notifications/ProfileNotification.vue';
import NotificationItem from "@/components/sections/notifications/Item";
import {Menu, MenuButton, MenuItems} from '@headlessui/vue'
import {defineProps, onMounted, onUnmounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {useAuth} from "@/stores/AuthStore";
import {useRouter} from "vue-router";
import {useNotifications} from "@/composables/useNotifications";
import {onClickOutside} from "@vueuse/core";
import {useTheme} from "@/stores/ThemeStore";
import Notifications from "@/components/icons/Notifications.vue";
import DoubleCheck from "@/components/icons/DoubleCheck.vue";
import LoadingCircle from "@/components/icons/LoadingCircle.vue";

const {readNotification} = useNotifications();
const {loadNotifications} = useAuth();
const {member, organization} = storeToRefs(useAuth());
const {notifications, totalNotifications} = storeToRefs(useAuth());
const {color} = useTheme();

const notificationsHash = ref('');
const hasNewNotifications = ref(false);

const router = useRouter();

const props = defineProps({
    notificationType: {
        type: String,
        validator: function (value) {
            return ['chat', 'app'].indexOf(value) !== -1;
        },
        required: true
    },
    defaultOpen: {
        type: Boolean,
        default: false
    }
});

let autoLoadNotifications = null;
const AUTLOAD_NOTIFICATIONS_INTERVAL = 1000 * 60;

const notificationsList = ref(null);

onClickOutside(notificationsList, () => open.value = false);

const markAllLoader = ref(false);

onMounted(() => {
    notificationsHash.value = notifications.value.map(notification => notification.uid).join('');

    autoLoadNotifications = setInterval(() => {
        loadNotifications(organization.value.slug, member.value.uid).then((newNotifications) => {
            let hash = newNotifications.data.map(notification => notification.uid).join('');

            if (hash !== notificationsHash.value) {
                notificationsHash.value = hash;
                hasNewNotifications.value = true;
            }
        }).catch((error) => {
            console.error("Failed to load notifications:", error);
        })

    }, AUTLOAD_NOTIFICATIONS_INTERVAL);
});

onUnmounted(() => {
    clearInterval(autoLoadNotifications);
});

const open = ref(props.defaultOpen);
const goToViewAll = () => {
    router.push({name: 'notifications'}).then(() => {
        open.value = false;
    });
}

async function handleNotificationClick(notification) {
    if (typeof notification.read_at !== 'string') {
        await markNotificationAsRead(notification);
    }

    if (notification.go_to) {
        open.value = false;
        await router.push(notification.go_to);
    }
}

function handleOnClick() {
    open.value = !open.value;
    hasNewNotifications.value = false;
}

async function markAllAsRead() {
    if(totalNotifications.value === 0) {
        return;
    }

    markAllLoader.value = true;

    for (const notification of notifications.value) {
        if (typeof notification.read_at === 'string') {
            return;
        }

        await markNotificationAsRead(notification);
    }

    markAllLoader.value = false;
}

async function markNotificationAsRead(notification) {
    try {
        await readNotification(organization.value.slug, member.value.uid, notification.uid);
        totalNotifications.value--;
        notification.read_at = new Date().toISOString();
    } catch (error) {
        console.error("Failed to mark notification as read:", error);
    }
}

</script>
<style>
.white-gradient {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
