export function usePreloader() {
    const showPreloader = () => {
        document.getElementById('app').classList.remove('loaded');
        document.querySelector('.loader').classList.remove('!hidden');
    }

    const hidePreloader = () => {
        document.getElementById('app').classList.add('loaded');

        setTimeout(() => {
            document.querySelector('.loader').classList.add('!hidden');
        }, 500);
    }

    return {
        showPreloader,
        hidePreloader
    }
}