import {useAuth} from "../stores/AuthStore";
import {storeToRefs} from "pinia";

export const onboardingIncompleteGuard = (to, from, next) => {
    const {onboardingStatus} = storeToRefs(useAuth());

    if (onboardingStatus.value === null) {
        return next();
    }

    return next('/');
};
