import {api, routes} from "@/plugins/axios";
import {ref} from "vue";

export function useFields() {
    const fetching = ref(false);
    const getOrganizationFields = async (slug, page = 1, perPage = 10, search = '') => {
        fetching.value = true;
        return api.get(routes.fields.get(slug, false, page, perPage, search))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .finally(() => {
                fetching.value = false
            });
    }

    const getOrganizationHeadlinesAndFields = async (slug) => {
        fetching.value = true;

        return Promise.all([
            api.get(routes.headlines.get(slug)),
            api.get(routes.fields.get(slug, true))
        ]).then((responses) => {
            return Promise.resolve(responses);
        }).catch(err => {
            return Promise.reject(err);
        }).finally(() => {
            fetching.value = false;
        });
    }

    return {
        getOrganizationFields,
        getOrganizationHeadlinesAndFields
    }
}
