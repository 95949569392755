
export function useColorContrastRatio() {
    const black = [0, 0, 0];
    const white = [255, 255, 255];

    const WCAG_AA_CONTRAST_THRESHOLD = 3;

    const getContrastingColor = (organizationColor) => {
        const values = organizationColor.substring(4, organizationColor.length - 1).split(',');
        const color = values.map(value => parseInt(value.trim(), 10));

        const contrastWithBlack = getRatio(color, black);
        const contrastWithWhite = getRatio(color, white);

        if (contrastWithWhite > WCAG_AA_CONTRAST_THRESHOLD) {
            return 'white';
        }

        return contrastWithBlack > contrastWithWhite ? 'black' : 'white';
    }

    const getRatio = (color1, color2) => {
        const luminance1 = luminance(color1);
        const luminance2 = luminance(color2);

        return (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
    }

    const luminance = color => {
        const adjustChannel = channel => {
            channel /= 255.0;
            return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
        };
        const [r, g, b] = color;
        return 0.2126 * adjustChannel(r) + 0.7152 * adjustChannel(g) + 0.0722 * adjustChannel(b);
    };

    function hexToRgb(hex) {
        hex = hex.replace(/^#/, ''); // Remove the "#" symbol if present

        // Convert the hexadecimal values to decimal
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgb(${r}, ${g}, ${b})`;
    }

    return {
        getContrastingColor,
        hexToRgb
    }
}