import {ref} from "vue";
import {coordinator, routes} from "@/plugins/axios";

export function useUsers() {
    const fetching = ref(false);

    const createUser = async (firstName, lastName, phoneNumber) => {
        return coordinator.post(routes.users.create, {
            'first_name': firstName,
            'last_name': lastName,
            'phone_number': phoneNumber
        })
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    const createMembership = async (organizationUid, userUid) => {
        return coordinator.post(routes.users.memberships.create(userUid), {
            'organization': organizationUid,
        }).then((response) => {
            return Promise.resolve(response.data);
        });
    }

    const getUser = async (uid) => {
        return coordinator.get(routes.users.get(uid))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    return {createUser, createMembership, getUser, fetching}
}
