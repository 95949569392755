export default function useTimezone() {
    const convertToClientTime = (dateTime) => {
        const utcDate = new Date(dateTime);
        const timezoneOffsetMinutes = new Date().getTimezoneOffset();

        return new Date(utcDate.getTime() - (timezoneOffsetMinutes * 60 * 1000));
    };

    const getClientTimezone = () => {
        try {
            if (Intl && Intl.DateTimeFormat) {
                return Intl.DateTimeFormat().resolvedOptions().timeZone;
            } else {
                console.error("Intl.DateTimeFormat is not supported. Using a fallback timezone.");
                return "UTC";
            }
        } catch (error) {
            console.error("Error getting user timezone:", error);
            return "UTC";
        }
    }

    return {
        convertToClientTime,
        getClientTimezone
    };
}
