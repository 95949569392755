<template>
  <div>
    <div class="mx-auto flex items-center justify-center">
      <VerifyPhoneIcon/>
    </div>

    <div class="mt-3 text-center">
      <p class="font-medium text-default-1 text-body-5"> Verify your phone number
      </p>
      <div class="mt-2 mb-6">
        <p class="text-body-6 text-default-1">
          Please, enter the {{ OTP_LENGTH }} digit code sent to <span class="font-medium"> {{ phoneNumber }} </span>
        </p>
      </div>
    </div>

    <div class="flex justify-center">
      <OtpInput
          ref="otpInput"
          separator=""
          :num-inputs="OTP_LENGTH"
          input-type="number"
          :should-auto-focus="true"
          input-classes="bg-white border rounded focus:outline-none h-12 w-12 text-body-3 text-default-1 text-center font-medium
        p-0 mr-0.5 sm:mr-2 focus:ring-transparent focus:ring-offset-transparent focus:ring-shadow focus:border-primary-1"
          @on-change="updateOtp"
          @on-complete="completeOtp"
      />
    </div>

    <div v-if="error().length > 0" class="flex justify-center mt-4">
      <p class="text-body-7 font-medium text-interactive-3"> {{ error() }} </p>
    </div>

    <div class="flex justify-center items-center my-4">
      <div class="w-10 h-10" v-show="!countdownStore.countdownFinished">
        <Countdown :time="30"></Countdown>
      </div>

      <CoreButton
          v-if="countdownStore.countdownFinished"
          button-type="secondary"
          @click="handleOnResend"
      >
        Resend
      </CoreButton>

      <p v-if="!countdownStore.countdownFinished" id="countdown" class="ml-2 text-default-2 text-body-6 font-normal">
        You can resend the code in
        {{ countdownStore.timeLeft }} seconds</p>
    </div>

    <div class="flex flex-row justify-center">
      <CoreButton :disabled="!isOtpFilled || loading"
                  @click.prevent="handleConfirm"
                  :loading="loading"
                  label="Confirm"
      />
      <CoreButton class="ml-4"
                  button-type="secondary"
                  @click.prevent="emit('canceled')"
                  label="Cancel"
                  :disabled="loading"
      />
    </div>
  </div>
</template>
<script setup>
import VerifyPhoneIcon from '@/components/icons/phone/Verify.vue';
import CoreButton from '@/components/core/Button.vue';
import Countdown from "@/components/core/Countdown.vue";
import OtpInput from "vue3-otp-input";
import {computed, onBeforeUnmount, ref} from 'vue';

import {useCountdown} from "@/stores/CountdownStore";

const OTP_LENGTH = 6;

const countdownStore = useCountdown();

const props = defineProps({
    phoneNumber: {
        required: true,
        type: String
    },
    error: {
        required: false
    }
});

defineExpose({
    resetLoader
});

const emit = defineEmits([
    'canceled',
    'resend',
    'confirm',
    'clearErrors'
]);

const otp = ref('');
const otpInput = ref(null);
const loading = ref(false);

function resetLoader() {
    loading.value = false;
}

const isOtpFilled = computed(() => {
    return otp.value.length === OTP_LENGTH;
});

const updateOtp = function (value) {
    otp.value = value;
    emit('clearErrors');
}

const handleConfirm = () => {
    loading.value = true;
    emit('confirm', otp.value);
}

const handleOnResend = function () {
    otpInput.value.clearInput();
    countdownStore.resetTimer();
    countdownStore.startTimer(30);
    emit('resend');
}

const handleKeyPress = (event) => {
    if (event.key === 'Enter' && isOtpFilled.value) {
        emit('confirm', otp.value);
    }
}

const completeOtp = function (value) {
    otp.value = value;
    document.addEventListener('keydown', handleKeyPress);
}

onBeforeUnmount(() => {
    loading.value = false;
    document.removeEventListener('keydown', handleKeyPress);
});

</script>
