import { useAuth} from "../stores/AuthStore";
import {storeToRefs} from "pinia";

export const partiallyCompletedProfileGuard = (to, from, next) => {
    const {profileStatus} = storeToRefs(useAuth());

    if (profileStatus.value !== null && profileStatus.value === 'profile_partially_completed') {
        return next();
    }

    return next('/complete-profile-reminder');
};
