<template>
  <div>
    <div class="mx-auto flex items-center justify-center">
      <Done :color="color.hex"/>
    </div>

    <div class="flex flex-col mt-2 mb-6">
      <p class="text-center text-body-5 text-default-1 font-medium">Congratulations!</p>
      <p class="text-center text-body-6 text-default-1 mt-4">
        Your phone <span class="font-medium"> {{ phoneNumber }} </span> is verified.
      </p>
    </div>

    <div class="flex justify-center">
      <CoreButton @click="emit('finished')">Got it</CoreButton>
    </div>
  </div>
</template>
<script setup>
import CoreButton from '@/components/core/Button.vue';
import Done from "@/components/icons/phone/Done.vue";
import {useTheme} from "@/stores/ThemeStore";

const {color} = useTheme();

const props = defineProps({
    phoneNumber: {
        required: true,
        type: String
    }
});

const emit = defineEmits([
    'finished',
]);
</script>