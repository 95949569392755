import {ref} from 'vue'

const isMobile = ref(false)
const mobileModalVisibility = ref(false)

export function useIsMobile() {

    function updateIsMobile(){
        isMobile.value = window.innerWidth < 1024;
    }

    function initIsMobileListener() {
        window.addEventListener('resize', updateIsMobile);
        if(window.innerWidth < 1024) {
            isMobile.value = true;
            mobileModalVisibility.value = true;
        }
    }

    function clearIsMobileListener() {
        window.removeEventListener('resize', updateIsMobile);
    }
	
    return {
        isMobile,
        mobileModalVisibility,
        updateIsMobile,
        initIsMobileListener,
        clearIsMobileListener
    }
}