<template>
    <AppLayout>
        <router-view/>
    </AppLayout>
</template>
<script setup>
import AppLayout from "@/views/layouts/AppLayout.vue";
import {useLocale} from "@/stores/LocaleStore";
import {onMounted} from "vue";
import {usePreloader} from "@/composables/usePreloader";

const {hidePreloader} = usePreloader();

onMounted(() => {
    const {setPhoneNumberRegion, hasPhoneNumberRegion} = useLocale();

    if (!hasPhoneNumberRegion()) {
        setPhoneNumberRegion('US');
    }

    hidePreloader();
});
</script>
