import {defineStore} from 'pinia'
import {coordinator, routes} from "@/plugins/axios";

export const useVerifyPhoneNumber = defineStore('useVerifyPhoneNumber', {
    state: () => ({
        used: false,
        verified: false,
        loading: false,
        error: '',
        type: 'users'
    }),
    actions: {
        async sendOTP(uid, phoneNumber, token = null) {
            this.loading = true;
            this.error = '';

            let route = routes.users.phone_number.sendCode(uid);

            if (this.type === 'invitations') {
                route = routes.invitations.phone_number.sendCode(uid, token);
            }

            const data = {
                phone_number: phoneNumber,
            }

            return coordinator.post(route, data)
                .then(() => this.verified = false)
                .catch(() => this.verified = false)
                .finally(() => this.loading = false);
        },
        async checkOTP(uid, phoneNumber, otp, token = null) {
            this.loading = true;

            let route = routes.users.phone_number.checkCode(uid)

            if (this.type === 'invitations') {
                route = routes.invitations.phone_number.checkCode(uid, token);
            }

            const data = {
                phone_number: phoneNumber,
                code: otp
            }

            return coordinator.post(route, data)
                .then((response) => {
                    this.verified = true;
                    this.error = '';

                    return Promise.resolve(response);
                })
                .catch((err) => {
                    this.verified = false;

                    if (err.response.status === 400) {
                        this.error = err.response.data.message ?? 'The code you entered is incorrect.';
                    }

                    return Promise.reject(err);
                })
                .finally(() => this.loading = false)
        },
        setType(type) {
            if (!['users', 'invitations'].includes(type)) {
                return;
            }

            this.type = type;
        },
        reset() {
            this.used = false;
            this.verified = false;
            this.error = '';
            this.loading = false;
            this.type = 'users';
        }
    }
})