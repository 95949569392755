<template>
    <svg width="190" height="191" viewBox="0 0 190 191" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1298_3754)">
            <circle cx="95" cy="85.5407" r="75" fill="white"/>
        </g>
        <circle opacity="0.15" cx="95" cy="85.5406" r="75" :fill="color"/>
        <g clip-path="url(#clip0_1298_3754)">
            <circle cx="150.467" cy="19" r="17" fill="white"/>
            <path
                d="M179.063 31C179.063 47.0543 166.05 60.0625 150 60.0625C133.95 60.0625 120.938 47.0543 120.938 31C120.938 14.955 133.95 1.93751 150 1.93751C166.05 1.93751 179.063 14.955 179.063 31ZM150 36.8594C147.023 36.8594 144.609 39.2729 144.609 42.25C144.609 45.2272 147.023 47.6406 150 47.6406C152.977 47.6406 155.391 45.2272 155.391 42.25C155.391 39.2729 152.977 36.8594 150 36.8594ZM144.882 17.4829L145.751 33.4204C145.792 34.1662 146.409 34.75 147.156 34.75H152.844C153.591 34.75 154.208 34.1662 154.249 33.4204L155.118 17.4829C155.162 16.6774 154.521 16 153.714 16H146.286C145.479 16 144.838 16.6774 144.882 17.4829Z"
                :fill="color"/>
        </g>
        <g clip-path="url(#clip1_1298_3754)">
            <path
                d="M129.551 103.225V117.13C129.551 118.126 129.174 119.085 128.495 119.813C127.816 120.542 126.886 120.985 125.893 121.055C124.175 121.173 122.771 121.236 121.685 121.236C86.9331 121.236 58.764 93.0668 58.764 58.3146C58.764 57.2292 58.823 55.8253 58.9449 54.1067C59.0146 53.1135 59.4585 52.1837 60.1869 51.505C60.9153 50.8262 61.874 50.449 62.8697 50.4494H76.7753C77.2631 50.4489 77.7336 50.6298 78.0956 50.9568C78.4575 51.2839 78.6849 51.7337 78.7337 52.2191C78.8242 53.1236 78.9067 53.8433 78.9854 54.3899C79.7669 59.8441 81.3686 65.1488 83.736 70.1242C84.1096 70.9107 83.8657 71.8506 83.1579 72.3539L74.6713 78.418C79.8602 90.5085 89.4954 100.144 101.586 105.333L107.642 96.8618C107.89 96.5157 108.251 96.2675 108.663 96.1604C109.074 96.0533 109.511 96.0942 109.896 96.2758C114.87 98.6388 120.174 100.236 125.626 101.015C126.172 101.093 126.892 101.18 127.789 101.266C128.273 101.316 128.722 101.544 129.049 101.906C129.375 102.267 129.551 102.738 129.551 103.225Z"
                :fill="color"/>
        </g>
        <defs>
            <filter id="filter0_d_1298_3754" x="0" y="0.54068" width="190" height="190" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                               result="hardAlpha"/>
                <feOffset dy="10"/>
                <feGaussianBlur stdDeviation="10"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.333333 0 0 0 0 0.431373 0 0 0 0 0.901961 0 0 0 0.13 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1298_3754"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1298_3754" result="shape"/>
            </filter>
            <clipPath id="clip0_1298_3754">
                <rect width="60" height="60" fill="white" transform="translate(120 1.00001)"/>
            </clipPath>
            <clipPath id="clip1_1298_3754">
                <rect width="94.382" height="94.382" fill="white" transform="translate(46.9663 38.6517)"/>
            </clipPath>
        </defs>
    </svg>
</template>
<script setup>
import {useTheme} from "@/stores/ThemeStore";
import {computed} from "vue";

const props = defineProps({
    color: {
        type: String,
    }
});

const {color: themeColor} = useTheme();

const color = computed(() => {
    return props.color || themeColor.hex;
});

</script>
