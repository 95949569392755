<template>
    <div @click="handleOnClick" class="w-full max-w-xs p-4" role="alert">
        <div class="flex mb-3">
            <span class="mb-1 text-body-6 font-semibold">{{props.title}}</span>
        </div>
        <div class="flex items-center">
            <div class="text-body-6">
                <div class="text-body-6">{{ props.body }}</div>
                <span v-if="timeAgo" class="text-body-6 text-blue-600">{{ timeAgo }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import router from "../../router";
import {useTimeAgo} from "@vueuse/core";

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    body: {
        type: String,
        required: true
    },
    timestamp: {
        type: String,
        required: false,
        default: null
    },
    go_to: {
        type: String,
        required: false,
        default: null
    },
    type: {
        type: String,
        required: false,
        default: 'info'
    },
});

const timeAgo = useTimeAgo(new Date(props.timestamp), {
    showSecond: true,
    locale: 'en-US',
    updateInterval: 1000
});

function handleOnClick() {
    if (!props.go_to) {
        return;
    }

    router.push(props.go_to);
}

</script>

<style scoped>

</style>
