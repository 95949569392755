<template>
    <div :class="styleClasses.wrapper">
        <div :class="styleClasses.labelWrapper">
            <label class="text-body-6 font-medium mb-2" :class="styleClasses.labelColor" v-if="borderStyling">
                {{ label }}
                <span v-if="required" class="text-interactive-3 text-body-4">*</span>
            </label>
            <p v-if="optionalText" class="text-body-6 font-medium text-right text-default-3"> {{ optionalText }} </p>
        </div>

        <div :class="[styleClasses.inputWrapper]" class="relative">
            <input
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :placeholder="placeholder"
                :type="type"
                :disabled="isDisabled"
                :class="[styleClasses.inputError, styleClasses.input]"
            />
            <slot/>
            <p v-if="borderStyling && hasError" class="text-body-7 font-medium mt-1" :class="styleClasses.errorMessage"> {{ hintText }} </p>
        </div>
    </div>
</template>
<script setup>
import {computed} from '@vue/reactivity';
const props = defineProps({
    type: {
        type: String,
        default: 'text',
        validator: function (value) {
            return ['text', 'number', 'email'].indexOf(value) !== -1;
        },
    },
    placeholder: {
        type: String,
    },
    label: {
        type: String,
    },
    labelPosition: {
        type: String,
        default: 'above',
        validator: function (value) {
            return ['above', 'left'].indexOf(value) !== -1;
        }
    },
    hintText: {
        type: String,
        default: null
    },
    optionalText: {
        type: String,
        default: null
    },
    hasError: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: String
    },
    required: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    borderStyling: {
        type: Boolean,
        default: true
    },
    colorMode: {
        type: String,
        default: 'light',
    }
})

const emit = defineEmits(['update:modelValue'])

const styleClasses = computed(() => ({
    wrapper: {
        'flex flex-row align-center': props.labelPosition === 'left' && props.borderStyling
    },
    inputWrapper: {
        'ml-32 mt-1': props.labelPosition === 'left' && props.borderStyling
    },
    labelWrapper: {
        'flex flex-row justify-between': props.labelPosition === 'above' && props.borderStyling
    },
    label: {
        'flex flex-row align-baseline': props.labelPosition === 'left'
    },
    labelColor: props.colorMode === 'dark' ? 'text-dark-default-10' : 'text-default-1',
    inputError: {
        'border-interactive-3 focus:border-interactive-3 hover:border-interactive-3': props.hasError && !props.isDisabled,
        'border-interactive-9': !props.hasError && !props.isDisabled,
        'focus:border-primary-1 hover:border-primary-1': props.colorMode === 'light'
    },
    input: {
        'block w-full xxs:h-12 lg:h-10 text-body-6 font-normal text-default-1 rounded border border-interactive-9 focus:outline-none focus:ring-0' : props.borderStyling,
        'block w-full h-12 text-body-6 font-normal text-default-1 border-0' : !props.borderStyling
    },
    errorMessage: {
        'text-interactive-3': props.hasError,
        'text-gray-500': !props.hasError
    }
}));

</script>
