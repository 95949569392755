<template>
    <Menu as="div" class="relative inline-block text-left" ref="chatNotificationsDropdown">
        <div>
            <MenuButton
                @click="open = !open"
                class="inline-flex justify-center w-full  bg-white">
                <ProfileNotification :notificationCount="conversationList.length" :ping="ping" notificationType="chat" />
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95"
        >
            <div v-show="open">
                <MenuItems static class="origin-top-right absolute xxs:left-1/2 lg:left-auto xxs:right-auto xxs:translate-x-[-35%] xs:translate-x-[-47%] lg:translate-x-0 lg:right-0 mt-2 xxs:w-[310px] xs:w-[380px] lg:w-[418px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="flex px-6 pt-5 pb-4 items-center">
                        <p class="text-body-5 text-default-1 font-medium">Chat Notifications </p>
                        <div class="flex flex-center text-body-7 text-primary-1 font-normal ml-auto cursor-pointer" @click="markAllAsRead" v-if="conversationList.length > 0">
                            <div class="w-4 h-4 mr-2">
                                <LoadingCircle v-if="markAllLoader" :color="color.hex" />
                            </div>
                            <DoubleCheck :color="color.hex"/>
                            Mark all as read
                        </div>
                    </div>
                    <div class="border-none pb-2">
                        <div
                            v-if="conversationList.length > 0"
                            class="w-full mt-2 max-h-[500px] overflow-y-scroll px-3 mb-2 relative box-border"
                            :class="[conversationList.length > 7 ? '' : 'no-scrollbar']"
                        >
                            <div v-if="showPreloader" class="overflow-y-scroll no-scrollbar border-box px-1">
                                <div class="w-full h-[70px] skeleton-loader mt-1" v-for="i in 8" :key="i"></div>
                            </div>
                            <div v-if="conversationList.length > 7"
                                 class="white-gradient z-10 sticky top-[95%] w-full h-9 -mt-9"></div>
                            <div v-for="(conversation, i) in conversationList" :key="i">
                                <comet-chat-conversation-list-item
                                    :config="config"
                                    :theme="themeValue"
                                    :conversationKey="conversation.id"
                                    :conversation="conversation"
                                    @click="handleClick(conversation)"
                                />
                            </div>
                        </div>
                        <div v-else class="w-full h-[400px] flex justify-center items-center flex-col">
                            <Notifications :color="color.hex"/>
                            <p class="mt-6 text-body-5">You do not have any notifications</p>
                        </div>
                        <router-link
                            to="/notifications"
                            class="text-body-6 text-primary-1 font-medium flex justify-center bg-background-3 mx-3 rounded h-8 items-center"
                            @click.prevent="viewAll"
                        >
                            {{ buttonLabel }}
                        </router-link>
                    </div>
                </MenuItems>
            </div>
        </transition>
    </Menu>
</template>

<script setup>
import ProfileNotification from "@/components/icons/notifications/ProfileNotification.vue";
import CometChatConversationListItem
    from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/components/Chats/CometChatConversationListItem/CometChatConversationListItem.vue";
import {Menu, MenuButton, MenuItems} from "@headlessui/vue";
import LoadingCircle from "@/components/icons/LoadingCircle.vue";
import {computed, ref} from "vue";
import {useTheme} from "@/stores/ThemeStore";
import {
    DEFAULT_OBJECT_PROP,
    DEFAULT_STRING_PROP
} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/constants";
import {theme} from "@/components/cometchat-pro-vue-ui-kit/CometChatWorkspace/src/resources/theme";
import {onClickOutside} from "@vueuse/core";
import Notifications from "@/components/icons/Notifications.vue";
import DoubleCheck from "@/components/icons/DoubleCheck.vue";

const chatNotificationsDropdown = ref(null);
onClickOutside(chatNotificationsDropdown, () => (open.value = false));

const {color} = useTheme();

const props = defineProps({
    type: {...DEFAULT_STRING_PROP},
    item: {...DEFAULT_OBJECT_PROP},
    theme: {...DEFAULT_OBJECT_PROP},
    config: {...DEFAULT_OBJECT_PROP},
    ping: {
        type: Boolean,
        default: false,
        required: true,
    },
    conversationList: {
        type: Array,
        default: () => [],
        required: true,
    },
    markAllLoader: {
        type: Boolean,
        default: false,
        required: true,
    },
    showPreloader: {
        type: Boolean,
        default: false,
        required: true,
    }
})

const emit = defineEmits(['markAllAsRead', 'clickConversation', 'viewAll']);

const open = ref(false);

const themeValue = computed(() => {
    return Object.assign({}, theme, props.theme || {});
});

function viewAll() {
    emit('viewAll')
    open.value = false;
}

function handleClick(conversation) {
    emit('clickConversation', conversation);
    open.value = false;
}

function markAllAsRead() {
    emit('markAllAsRead');
    open.value = false;
}

const buttonLabel = computed(() => {
    return props.conversationList.length > 0 ? 'View all' : 'Go to Chat';
})
</script>