/* eslint-disable */
import {defineStore, storeToRefs} from "pinia";
import {computed, reactive, ref} from "vue";
import {coordinator, routes} from "@/plugins/axios";
import {cookieStorage} from "@/pinia/cookieStorage";
import {useTheme} from "@/stores/ThemeStore";
import {useOrganizations} from "@/composables/useOrganizations";
import {useMembers} from "@/composables/useMembers";
import {useNotifications} from "@/composables/useNotifications";
import {useMemberFields} from "@/composables/useMemberFields";
import {useFields} from "@/composables/useFields";
import {useUsers} from "@/composables/useUsers";
import {getFirebaseTokenOrRequestPermission} from "@/plugins/firebase";
import {useSignUp} from "./SignUpStore";
import {useChat} from "@/composables/useChat";
import moment from "moment";
import {useCheckFeature} from "@/composables/useCheckFeature";

export const useAuth = defineStore('useAuth', () => {
    const {getOrganizationBySlug} = useOrganizations();
    const {getMemberFields} = useMemberFields();
    const {setTheme} = useTheme();
    const {
        getMemberByUserUid,
        getProfileStatus,
        getOnboardingStatus,
        getFieldsStatus,
    } = useMembers();
    const {getNotifications, storeFcmToken} = useNotifications();
    const {getOrganizationFields} = useFields();
    const {inProgress: signUpInProgress} = storeToRefs(useSignUp());
    const {getChatAccessToken} = useChat();
    const {getUser} = useUsers();

    const loading = ref(false);
    const token = ref(null);
    const notificationToken = ref(null);
    const user = ref(null);
    const member = ref(null);
    const fields = ref([]);
    const notifications = ref([]);
    const totalNotifications = ref(0);
    const organization = ref(null);
    const chatToken = ref(null);
    const errors = reactive({
        phone_number: null,
        code: null
    });

    const profileStatus = ref(null);
    const onboardingStatus = ref(null);
    const fieldsStatus = ref(null);

    const {getMemberFeatures} = useCheckFeature();

    const isAuthenticated = computed(() => {
        return token.value !== null;
    });

    const hasMember = computed(() => {
        return member.value !== null;
    });

    const hasToken = computed(() => {
        return token.value !== '';
    });

    const hasOrganization = computed(() => {
        return organization.value !== null;
    });

    const userFullName = computed(() => {
        if (member.value === null) {
            return '';
        }

        return `${member.value.first_name} ${member.value.last_name}`;
    });

    const isAdmin = computed(() => {
        if(!member.value) {
            return false;
        }

        return  member.value.roles.some(
            (role) => role.title === "Organization-admin"
        );
    });

    const isContentAdmin = computed(() => {
        if(!member.value) {
            return false;
        }

        return member.value.roles.some(
            (role) => role.title === "Content-admin"
        );
    });

    const isRepresentative = computed(() => {
        if(!member.value) {
            return false;
        }

        return member.value.roles.some(
            (role) => role.title === "Representative"
        );
    });

    const hasOrganizationFields = computed(() => {
        if (organization.value && organization.value.slug && organization.value.fields && organization.value.fields.length > 0) {
            return isAdmin || organization.value.fields.some(field => field.permissions.read);
        }
        return false;
    });

    const showCompleteProfileInfoBox = computed(() => {
        return (
            fieldsStatus.value === "member_fields_incomplete" &&
            moment().subtract(30, "days").isBefore(member.value.created_at) &&
            moment(member.value.created_at).isBefore(moment())
        );
    });

    const setOrganizationName = (name) => {
        organization.value.name = name;
    };

    async function getFields(organizationSlug) {
        return getOrganizationFields(organizationSlug).then(
            (organizationFieldsData) => {
                organization.value.fields = organizationFieldsData.data;
            }
        );
    }

    async function init(organizationSlug) {
        organization.value = {
            slug: organizationSlug
        };

        return getOrganizationBySlug(organizationSlug).then(async (organizationData) => {
            organization.value = organizationData.data;
            setTheme(organizationData.data.theme_settings);
            if (!user.value && cookieStorage.getItem('user-uid') !== 'null') {
                user.value = {
                    uid: cookieStorage.getItem('user-uid'),
                    phone_number: cookieStorage.getItem('phone_number')
                };
            }

            if (user.value && user.value.uid && member.value === null && !signUpInProgress.value) {
                loading.value = true;
                try {
                    await getMemberInfo(organizationSlug, user.value.uid);
                } catch (e) {
                }

                try {
                    getFirebaseTokenOrRequestPermission().then(async (token) => {
                        if (member.value) {
                            const fcmToken = await storeFcmToken(organization.value.slug, member.value.uid, token);
                            notificationToken.value = fcmToken.data;
                        }
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        });
    }

    async function sendAuthenticationCode(phoneNumber) {
        loading.value = true;

        return coordinator.post(routes.auth.sendCode, {
            'phone_number': phoneNumber
        }).catch(err => {
            errors.phone_number = err.response.data.errors.phone_number;
            return Promise.reject(err);
        }).finally(() => loading.value = false);
    }

    async function checkAuthenticationCode(phoneNumber, code) {
        loading.value = true;
        return coordinator.post(routes.auth.validateCode, {
            'phone_number': phoneNumber,
            'code': code
        }).then(async (r) => {
            user.value = r.data.user;
            token.value = r.data.token;

            cookieStorage.setItem('user-uid', user.value.uid);

            if (hasOrganization.value) {
                try {
                    await getMemberInfo(organization.value.slug, user.value.uid)
                } catch (e) {
                    return e.response
                }

                try {
                    getFirebaseTokenOrRequestPermission().then(async (token) => {
                        const fcmToken = await storeFcmToken(organization.value.slug, member.value.uid, token);
                        notificationToken.value = fcmToken.data;
                    });
                } catch (e) {
                    console.error(e);
                }
            }

            return Promise.resolve(r);
        }).catch((err) => {
            loading.value = false;
            if (err.response.status === 400) {
                errors.code = err.response.data.message;
            } else {
                errors.code = err.response.data.errors;
            }

            return Promise.reject(err);
        });
    }

    async function getMemberInfo(organizationSlug, userUid) {
        loading.value = true;
        return getMemberByUserUid(organizationSlug, userUid)
            .then(async (response) => {
                member.value = response.data;

                let request = await getMemberFields(
                    organizationSlug,
                    member.value.uid
                ).catch((err) => {
                    console.error(err);
                });
                fields.value = request.data;

                request = await getNotifications(
                    organizationSlug,
                    member.value.uid
                ).catch((err) => {
                    console.error(err);
                });
                notifications.value = request.data;
                totalNotifications.value = request.meta.total_unread;

                await getFields(organizationSlug);

                profileStatus.value = await getProfileStatus(
                    organizationSlug,
                    member.value.uid
                ).catch((err) => {
                    console.error(err);
                });

                fieldsStatus.value = await getFieldsStatus(
                    organizationSlug,
                    member.value.uid
                ).catch((err) => {
                    console.error(err);
                });

                onboardingStatus.value = await getOnboardingStatus(
                    organizationSlug,
                    member.value.uid
                ).catch((err) => {
                    console.error(err);
                });

                const memberFeatures = await getMemberFeatures(organizationSlug);

                if(memberFeatures.chat) {
                    chatToken.value = await getChatAccessToken(organizationSlug)
                        .then((response) => {
                            return response.token;
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }

                return Promise.resolve(true);
            })
            .catch(() => {
                getUser(userUid).catch((err) => {
                    if (err.response.status === 404) {
                        token.value = null;
                        user.value = null;
                        member.value = null;
                    }
                });
            })
            .finally(() => {
                loading.value = false;
            });
    }

    async function loadNotifications() {
        return getNotifications(organization.value.slug, member.value.uid).then((response) => {
            notifications.value = response.data;
            totalNotifications.value = response.meta.total_unread;

            return Promise.resolve(response);
        });
    }

    return {
        loading,
        token,
        chatToken,
        notificationToken,
        user,
        member,
        fields,
        notifications,
        organization,
        errors,
        isAuthenticated,
        isAdmin,
        isContentAdmin,
        isRepresentative,
        hasToken,
        hasMember,
        hasOrganization,
        userFullName,
        init,
        getMemberInfo,
        sendAuthenticationCode,
        checkAuthenticationCode,
        getOrganizationBySlug,
        getNotifications,
        hasOrganizationFields,
        profileStatus,
        fieldsStatus,
        onboardingStatus,
        loadNotifications,
        totalNotifications,
        setOrganizationName,
        showCompleteProfileInfoBox,
    };
}, {
    persist: [
        {
            key: 'auth',
            storage: cookieStorage,
            paths: ['token'],
            debug: true,
        },
    ]
});
