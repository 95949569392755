import {useAuth} from "@/stores/AuthStore";
import {useSignUp} from "@/stores/SignUpStore";

const auth = function({ next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (signUp.inProgress) {
        return next({
            name: 'register'
        });
    }

    if (!auth.isAuthenticated) {
        return next({
            name: 'login'
        });
    }

    return next();
}

const guest = function({ next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (auth.isAuthenticated && !signUp.inProgress && auth.member) {
        return next({
            name: 'dashboard'
        });
    }

    return next();
}

const requestAccessMiddleware = function({ next }) {
    const auth = useAuth();
    const signUp = useSignUp();

    if (!auth.isAuthenticated || auth.isAuthenticated && signUp.inProgress) {
        return next({
            name: 'login'
        });
    }

    if (auth.isAuthenticated && !signUp.inProgress && auth.member) {
        return next({
            name: 'dashboard'
        });
    }

    if(!auth.hasOrganization) {
        return next({
            name: 'launchpad'
        });
    }

    return next();
}

export {auth, guest, requestAccessMiddleware}
