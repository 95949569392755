<template>
  <div class="mx-auto flex items-center justify-center">
    <VerifyPhoneIcon/>
  </div>

  <div class="mt-2 mb-6 flex justify-center">
    <p class="text-body-6 text-default-1">
      Your phone <span class="font-medium"> {{ phoneNumber }} </span> is already in the system.<br>
      Please, log in.
    </p>
  </div>

  <div class="flex justify-center">
    <CoreButton @click.prevent="goToLogin">Log in</CoreButton>
  </div>
</template>

<script setup>
import CoreButton from '@/components/core/Button.vue';
import VerifyPhoneIcon from '@/components/icons/phone/Verify.vue';
import {useRouter} from "vue-router";

const props = defineProps({
    phoneNumber: {
        required: true,
        type: String
    }
});

const router = useRouter();

function goToLogin() {
    router.push({name: 'login', query: {phoneNumber: props.phoneNumber}});
}

</script>
