import {useAuth} from "../stores/AuthStore";
import {storeToRefs} from "pinia";

export const onboardingCompleteGuard = (to, from, next) => {
    const {onboardingStatus} = storeToRefs(useAuth());

    if (onboardingStatus.value !== null) {
        return next();
    }

    return next('/onboarding-reminder');
};
