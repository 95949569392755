import {defineStore} from 'pinia'
import {computed, reactive, ref} from "vue";
import BasicInfo from "@/views/register/BasicInfo.vue";
import SelectOrganization from "@/views/register/SelectOrganization.vue";
import {useUsers} from "@/composables/useUsers";
import {cookieStorage} from "@/pinia/cookieStorage";

export const useSignUp = defineStore('useSignUp', () => {
    const {createUser: makeUser, createMembership: makeMembership} = useUsers();

    const loading = ref(false);

    const user = reactive({
        uid: null,
        first_name: null,
        last_name: null,
        phone_number: null,
    });

    const membershipCreated = ref(false);

    const currentStep = ref(0);

    const steps = ref([
        BasicInfo,
        SelectOrganization
    ]);

    const phoneVerificationModal = ref(false);

    const errors = reactive({
        first_name: null,
        last_name: null,
        phone_number: null
    });

    const inProgress = ref(false);

    const step = computed(() => {
        if (currentStep.value === 0) {
            return BasicInfo;
        }

        if (currentStep.value === 1) {
            return SelectOrganization;
        }

        return null;
    });

    const createUser = async (firstName, lastName, phoneNumber) => {
        loading.value = true;
        inProgress.value = true;

        return makeUser(firstName, lastName, phoneNumber)
            .then(userData => {
                user.uid = userData.data.uid;
                cookieStorage.setItem('user-uid', user.uid);

                user.first_name = userData.data.first_name;
                user.last_name = userData.data.last_name;
                user.phone_number = userData.data.phone_number;

                phoneVerificationModal.value = true;
            }).catch(error => {
                errors.phone_number = error.response.data.errors.phone_number ?? null;
                errors.first_name = error.response.data.errors.first_name ?? null;
                errors.last_name = error.response.data.errors.last_name ?? null;
            }).finally(() => {
                loading.value = false;
            });
    }

    const createMembership = async (organizationUid) => {
        loading.value = true;

        return makeMembership(organizationUid, user.uid).then(() => {
            membershipCreated.value = true;
            clearUser();
            resetErrors();
            currentStep.value = 0;
            inProgress.value = false;
        });
    }

    const resetErrors = () => {
        errors.last_name = null;
        errors.first_name = null;
        errors.phone_number = null;
    }

    const clearUser = () => {
        user.uid = null;
        user.first_name = null;
        user.last_name = null;
        user.phone_number = null;
    }

    const nextStep = () => {
        currentStep.value ++;
    }

    const showPhoneVerification = () => {
        phoneVerificationModal.value = true;
    }

    const hidePhoneVerification = () => {
        phoneVerificationModal.value = false;
    }

    const reset = () => {
        clearUser();
        resetErrors();
        membershipCreated.value = false;
        inProgress.value = false;
        currentStep.value = 0;
    }

    return {
        loading,
        inProgress,
        phoneVerificationModal,
        user,
        steps,
        step,
        currentStep,
        errors,
        createUser,
        createMembership,
        resetErrors,
        clearUser,
        nextStep,
        showPhoneVerification,
        hidePhoneVerification,
        reset,
        membershipCreated
    }
}, {
    persist: [
        {
            key: 'sign-up',
            storage: localStorage,
            paths: ['currentStep', 'user', 'inProgress'],
            debug: true,
        },
    ]
});
