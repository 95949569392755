import {coordinator, routes} from "@/plugins/axios";


export function useMemberships() {
    const getMemberships = (userUid,   page = 1, perPage = 10) => {
        return coordinator.get(routes.users.memberships.list(userUid, page, perPage)).then((response) => {
            return Promise.resolve(response.data);
        }).catch((err) => {
            return Promise.reject(err);
        });
    }
    return {
        getMemberships
    }
}