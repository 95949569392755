<template>
  <img
    :src="primaryIcon"
    class="w-8 h-8"
    alt=""
    :class="
      (hasClosingIcon || hasPendingIcon || hasSuccessIcon || hasFailIcon) &&
      'absolute'
    "
  />
  <SuccessIcon
    :color="secondaryIconColor"
    v-if="hasSuccessIcon"
    class="relative mt-5 ml-6"
  />
  <PendingIcon
    :color="secondaryIconColor"
    v-if="hasPendingIcon"
    class="relative mt-5 ml-6"
  />
  <TimeClosingIcon
    :color="secondaryIconColor"
    v-if="hasClosingIcon"
    class="relative mt-5 ml-6"
  />
  <img
    v-if="hasFailIcon"
    src="@/assets/icons/notifications/fail.svg"
    class="w-[14px] h-[14px] relative mt-5 ml-6"
    alt="fail"
  />
</template>
<script setup>
import { computed, defineProps } from "vue";
import SuccessIcon from "@/components/icons/notifications/SuccessIcon.vue";
import PendingIcon from "@/components/icons/notifications/PendingIcon.vue";
import TimeClosingIcon from "@/components/icons/notifications/TimeClosingIcon.vue";

import { useTheme } from "@/stores/ThemeStore";

const { color: themeColor } = useTheme();

const props = defineProps({
    userNameInitial: {
        type: String,
    },
    category: {
        type: String,
        default: 'default',
        required: true,
    },
});

const primaryIcon = computed(() => {
    switch (props.category) {
    case "member-welcome":
        return require("@/assets/icons/notifications/member-welcome.svg");
    case "admin-member-profile":
        return require("@/assets/icons/notifications/member-profile.svg");
    case "groups-success":
        return require("@/assets/icons/notifications/groups.svg");
    case "new-document":
    case "document-created":
        return require("@/assets/icons/notifications/document.svg");
    case "new-resource":
    case "resource-created":
        return require("@/assets/icons/notifications/resource.svg");
    case "new-blog-post":
    case "blog-post-created":
        return require("@/assets/icons/notifications/blog_post.svg");
    case "new-event":
    case "event-created":
        return require("@/assets/icons/notifications/event.svg");
    case "new-email":
        return require("@/assets/icons/notifications/email.svg");
    case "new-poll":
    case "poll-pending":
    case "poll-closing":
    case "poll-created":
        return require("@/assets/icons/notifications/poll.svg");
    case "import-success":
    case "import-failed":
        return require("@/assets/icons/notifications/import.svg");
    case "default":
        return require("@/assets/icons/notifications/notifications-default.svg");
    default:
        return require("@/assets/icons/notifications/notifications-default.svg");
    }
});

const secondaryIconColor = computed(() => {
    switch (props.category) {
    case "member-welcome":
    case "admin-member-profile":
        return "#34C38F";
    case "groups-success":
        return "#FF9800";
    case "document-created":
    case "resource-created":
    case "blog-post-created":
    case "event-created":
        return "#50A5F1";
    case "new-poll":
    case "poll-pending":
    case "poll-closing":
        return "#20CED9";
    case "import-success":
        return "#7950F2";
    case "import-failed":
        return "#FA5252";
    default:
        return themeColor.hex;
    }
});

const hasSuccessIcon = computed(() => {
    return [
        "member-welcome",
        "admin-member-profile",
        "groups-success",
        "document-created",
        "resource-created",
        "blog-post-created",
        "event-created",
        "poll-created",
        "import-success",
    ].includes(props.category);
});

const hasPendingIcon = computed(() => {
    return ["poll-pending"].includes(props.category);
});

const hasClosingIcon = computed(() => {
    return ["poll-closing"].includes(props.category);
});

const hasFailIcon = computed(() => {
    return ["import-failed"].includes(props.category);
});
</script>
