<template>
  <span v-if="count" class="min-w-2 rounded-full px-1 py-0.5 text-tag min-h-2 text-body-7 font-medium text-white flex bg-notification-primary-9 text-center justify-center items-center">{{ count }}</span>
</template>
<script>
import {
  DEFAULT_OBJECT_PROP,
  DEFAULT_NUMBER_PROP,
} from "../../../resources/constants";

import { badgeStyle } from "./style";

/**
 * Shows a badge count.
 *
 * @displayName CometChatBadgeCount
 */
export default {
  name: "CometChatBadgeCount",
  props: {
    /**
     * Theme of the UI.
     */
    theme: { ...DEFAULT_OBJECT_PROP },
    /**
     * The count value to display.
     */
    count: { ...DEFAULT_NUMBER_PROP },
  },
  computed: {
    /**
     * Computed styles for the component.
     */
    styles() {
      return badgeStyle(this.theme);
    },
  },
};
</script>