import {api, routes} from "@/plugins/axios";
import {ref} from "vue";
import {CometChat} from "@cometchat-pro/chat";

export function useChat() {
    const fetching = ref(false);

    async function getChatAccessToken(orgSlug){
        fetching.value = true;
        return api.get(routes.chat.getAccessToken(orgSlug))
            .then(response => {
                return Promise.resolve(response.data);
            })
            .catch(err => {
                return Promise.reject(err);
            })
            .finally(() => {
                fetching.value = false
            });
    }

    async function doChatLogin(token) {
        return CometChat.getLoggedinUser()
            .then((user) => {
                if (user) {
                    return Promise.resolve(user);
                }

                return CometChat.login(token).then(user => {
                    return Promise.resolve(user);
                }, error => {
                    if(error.name !== 'LOGIN_IN_PROGRESS') {
                        return Promise.reject(error);
                    }
                });
            });
    }

    return {
        getChatAccessToken,
        doChatLogin,
    }
}
