<template>
    <div
        :class="[isRead ? '' : 'bg-background-3 mt-1 border-white read']"
        class="relative xxs:flex-row xxs:justify-center xxs:items-center w-full xxs:px-4 lg:px-3 py-4 rounded flex flex-wrap lg:items-center border-t border-interactive-9 notification-item first:border-t-0"
    >
        <div
            :class="[isInNotificationBar ? 'items-start' : 'items-center']"
            class="flex flex-1 justify-center"
        >
            <div v-if="hasCheckbox" class="flex w-6 mr-4 justify-start">
                <Checkbox
                    :model-value="isOptionSelected"
                    @update:model-value="$emit('update:model-value', notification.uid)"
                ></Checkbox>
            </div>
            <div class="flex flex-1 items-center cursor-pointer">
                <div class="w-8 xxs:ml-1 lg:ml-0">
                    <NotificationIcon :category="props.notification.category"/>
                </div>
                <div
                    :class="[isInNotificationBar ? 'lg:flex-col' : 'lg:flex-row', 'flex xxs:flex-col flex-1']"
                    @click="$emit('clicked')"
                >
                    <div
                        class="xxs:flex-1 xxs:mt-0 xxs:pl-0 xxs:ml-4 lg:flex-1 lg:mt-0 text-body-6 text-default-1 [&>a]:text-primary-1 pl-2 whitespace-break-spaces break-words"
                        v-html="props.notification.content"
                    ></div>
                    <div
                        :class="[isInNotificationBar ? 'lg:w-full' : 'lg:w-[16%]']"
                        class="xxs:w-auto xxs:mt-1 xxs:justify-start xxs:ml-2 xxs:ml-4 text-body-7 text-default-3 flex lg:justify-center items-center"
                    >
                        {{ formattedTimeAgo }}
                    </div>
                </div>
                <div
                    :class="[isInNotificationBar ? 'hidden' : 'xxs:hidden lg:flex']"
                    class="xxs:justify-start lg:w-[16%] lg:justify-center lg:items-center"
                >
                    <NotificationTag
                        :type="props.notification.type"
                        is-active
                        size="small"
                    />
                </div>
                <img
                    v-if="!isInNotificationBar"
                    src="@/assets/icons/trash-dark.svg"
                    @click="deleteItem"
                />
                <div
                    class="xxs:absolute xxs:top-0 xxs:left-0 xxs:justify-start md:relative lg:w-[5%] relative lg:flex lg:justify-center"
                >
                    <div
                        v-if="!isRead"
                        class="rounded-full w-4 h-4 flex justify-center items-center bg-primary-1 opacity-20"
                    ></div>
                    <div
                        v-if="!isRead"
                        class="rounded-full w-1.5 h-1.5 bg-primary-1 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import {defineProps, computed, ref, onMounted, onUnmounted} from "vue";
import NotificationIcon from "@/components/icons/notifications/NotificationIcon";
import NotificationTag from "@/components/core/Tag";
import {useRouter} from "vue-router";
import Checkbox from "@/components/core/Checkbox.vue";
import {useNotifications} from "@/composables/useNotifications";
import {useAuth} from "@/stores/AuthStore";
import {toast} from "vue3-toastify";
import useTimezone from "@/composables/useTimezone";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

const props = defineProps({
    notification: {
        type: Object,
        default: () => {
        },
        required: true,
    },
    isInNotificationBar: {
        type: Boolean,
        default: false,
    },
    selectedNotifications: {
        type: Array,
        default: () => [],
    },
    hasCheckbox: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['clicked', 'deleted'])
const {deleteNotification} = useNotifications();
const {organization, member} = useAuth();
const {convertToClientTime} = useTimezone();

const isRead = computed(() => {
    return (typeof props.notification.read_at === 'string');
})

const formattedTimeAgo = ref('');
let interval = ref(null);

function formatTimeAgo() {
    const date = convertToClientTime(new Date(props.notification.created_at));

    const timestamp = date.getTime();
    formattedTimeAgo.value = timeAgo.format(timestamp - 60 * 1000);
}

const router = useRouter()

onMounted(() => {
    formatTimeAgo();
    interval.value = setInterval(formatTimeAgo, 60000);
})

onUnmounted(() => {
    clearInterval(interval);
})

const isOptionSelected = computed(() => {
    return props.selectedNotifications.filter(selected => selected === props.notification.uid).length > 0
})

function deleteItem() {
    deleteNotification(organization.slug, member.uid, props.notification.uid).then(() => {
        emit('deleted');
        toast.success('Notification was successfully deleted');
    });
}
</script>

<style>
.notification-item.read + .notification-item {
    border-top: 0;
}
</style>
