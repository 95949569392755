export function useWidgetDemoData() {
    /* eslint-disable no-useless-escape */
    const newsDemoData = [
        {
            "uid": "TNiFUpHp64",
            "organization": "demo",
            "title": "Sed vestibulum velit sed lorem malesuada, at tempor dolor porta.",
            "body": "<p>Sed vestibulum velit sed lorem malesuada, at tempor dolor porta. Sed convallis est metus, eu viverra lectus lobortis at. Donec at felis eget mi maximus bibendum in ut elit. Nam euismod elit mauris, ut ultricies nisi rhoncus vel. Vestibulum et erat placerat, fringilla nibh ut, sollicitudin metus. Phasellus tincidunt lorem et ex pretium, vel eleifend purus posuere. Ut sed gravida arcu. Vivamus convallis dui nec tortor tincidunt, in vestibulum ex sodales. Nullam non orci quis justo euismod dignissim. Nullam volutpat venenatis turpis, eget elementum lacus accumsan ut. Nam in ullamcorper velit. Sed dignissim neque nec nisi rutrum porttitor. Nullam justo libero, consequat sit amet ultrices ut, volutpat tincidunt sem.<\/p>",
            "tags": [],
            "thumbnail": "http://localhost:3001/u/demo/files/download/rWpY7Fz5rT3csWE6QHOYluu1xHz0ow8KKicBeoVs.jpg",
            "cover": "http://localhost:3001/u/demo/files/download/IQaCo7PvlHYm1M3W3rmoGb1l5sHKDISfgU9y4lGN.jpg",
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 11:56:06",
            "deleted_at": null
        },
        {
            "uid": "1vBvV6AwOD",
            "organization": "demo",
            "title": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi interdum id ligula ut interdum. Cras in elit viverra, sodales eros sed, aliquam quam. Proin consectetur euismod urna ac tristique. Praesent malesuada consectetur dui quis lacinia. Praesent tin",
            "body": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi interdum id ligula ut interdum. Cras in elit viverra, sodales eros sed, aliquam quam. Proin consectetur euismod urna ac tristique. Praesent malesuada consectetur dui quis lacinia. Praesent tincidunt ante cursus pharetra ullamcorper. Sed sagittis pulvinar nisi vitae ultricies. Praesent sodales vitae sem sed tincidunt. Sed aliquam neque sit amet massa scelerisque tristique.<\/p>",
            "tags": [],
            "thumbnail": null,
            "cover": null,
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 11:56:24",
            "deleted_at": null
        },
        {
            "uid": "d_sjnIcG3N",
            "organization": "demo",
            "title": "Donec a odio vel leo ullamcorper aliquet sed at felis. Sed rhoncus ullamcorper tortor et consectetur. Cras vehicula ipsum orci, at consequat leo rhoncus mollis. Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit am",
            "body": "<p>Donec a odio vel leo ullamcorper aliquet sed at felis. Sed rhoncus ullamcorper tortor et consectetur. Cras vehicula ipsum orci, at consequat leo rhoncus mollis. Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit amet, elementum odio. Suspendisse dictum justo nec laoreet accumsan. Aenean bibendum augue id cursus mattis. Donec efficitur ullamcorper tellus eu efficitur. Vestibulum sed ante eu leo pharetra facilisis ut sodales est.<\/p>",
            "tags": [],
            "thumbnail": "http://localhost:3001/u/demo/files/download/rWpY7Fz5rT3csWE6QHOYluu1xHz0ow8KKicBeoVs.jpg",
            "cover": "http://localhost:3001/u/demo/files/download/IQaCo7PvlHYm1M3W3rmoGb1l5sHKDISfgU9y4lGN.jpg",
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 11:56:49",
            "deleted_at": null
        },
        {
            "uid": "u6AHWgP6lw",
            "organization": "demo",
            "title": "Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit am",
            "body": "<p>Donec a odio vel leo ullamcorper aliquet sed at felis. Sed rhoncus ullamcorper tortor et consectetur. Cras vehicula ipsum orci, at consequat leo rhoncus mollis. Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit amet, elementum odio. Suspendisse dictum justo nec laoreet accumsan. Aenean bibendum augue id cursus mattis. Donec efficitur ullamcorper tellus eu efficitur. Vestibulum sed ante eu leo pharetra facilisis ut sodales est.<\/p>",
            "tags": [],
            "thumbnail": "http://localhost:3001/u/demo/files/download/rWpY7Fz5rT3csWE6QHOYluu1xHz0ow8KKicBeoVs.jpg",
            "cover": "http://localhost:3001/u/demo/files/download/IQaCo7PvlHYm1M3W3rmoGb1l5sHKDISfgU9y4lGN.jpg",
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 12:01:35",
            "deleted_at": null
        },
        {
            "uid": "0rD2NVHxK-",
            "organization": "demo",
            "title": "Vivamus convallis dui nec tortor tincidunt, in vestibulum ex sodales.",
            "body": "<p>Sed vestibulum velit sed lorem malesuada, at tempor dolor porta. Sed convallis est metus, eu viverra lectus lobortis at. Donec at felis eget mi maximus bibendum in ut elit. Nam euismod elit mauris, ut ultricies nisi rhoncus vel. Vestibulum et erat placerat, fringilla nibh ut, sollicitudin metus. Phasellus tincidunt lorem et ex pretium, vel eleifend purus posuere. Ut sed gravida arcu. Vivamus convallis dui nec tortor tincidunt, in vestibulum ex sodales. Nullam non orci quis justo euismod dignissim. Nullam volutpat venenatis turpis, eget elementum lacus accumsan ut. Nam in ullamcorper velit. Sed dignissim neque nec nisi rutrum porttitor. Nullam justo libero, consequat sit amet ultrices ut, volutpat tincidunt sem.<\/p>",
            "tags": [],
            "thumbnail": "http://localhost:3001/u/demo/files/download/rWpY7Fz5rT3csWE6QHOYluu1xHz0ow8KKicBeoVs.jpg",
            "cover": "http://localhost:3001/u/demo/files/download/IQaCo7PvlHYm1M3W3rmoGb1l5sHKDISfgU9y4lGN.jpg",
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 12:03:58",
            "deleted_at": null
        },
        {
            "uid": "u6AHWg3Alw",
            "organization": "demo",
            "title": "Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit am",
            "body": "<p>Donec a odio vel leo ullamcorper aliquet sed at felis. Sed rhoncus ullamcorper tortor et consectetur. Cras vehicula ipsum orci, at consequat leo rhoncus mollis. Nulla ac dapibus magna, vel pellentesque eros. Vivamus a elit placerat, lobortis mauris sit amet, elementum odio. Suspendisse dictum justo nec laoreet accumsan. Aenean bibendum augue id cursus mattis. Donec efficitur ullamcorper tellus eu efficitur. Vestibulum sed ante eu leo pharetra facilisis ut sodales est.<\/p>",
            "tags": [],
            "thumbnail": "http://localhost:3001/u/demo/files/download/rWpY7Fz5rT3csWE6QHOYluu1xHz0ow8KKicBeoVs.jpg",
            "cover": "http://localhost:3001/u/demo/files/download/IQaCo7PvlHYm1M3W3rmoGb1l5sHKDISfgU9y4lGN.jpg",
            "total_likes": 0,
            "is_liked": false,
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "published_at": null,
            "created_at": "2024-05-30 12:01:35",
            "deleted_at": null
        },
    ]

    const inboxDemoData = [
        {
            "uid": "J2VZHZv_eU",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 14:01:49",
            "email": {
                "uid": "FQ7isDAtxp",
                "subject": "Aliquam ornare orci tellus, malesuada accumsan nisl suscipit sed",
                "body": "<p>Aliquam ornare orci tellus, malesuada accumsan nisl suscipit sed. Duis non tempor nulla. Praesent vel enim quis libero pellentesque aliquam a id ex. Etiam vitae augue vel sapien posuere congue a vitae ante. Morbi semper varius ipsum, ut finibus odio semper non. Aenean quis pulvinar orci. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris eleifend leo velit, et mattis augue ultrices a.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 14:01:50",
                "scheduled_at": null,
                "created_at": "2024-06-17 14:01:45",
                "files": []
            }
        },
        {
            "uid": "LghiPAMNj9",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 14:00:26",
            "email": {
                "uid": "42ruq8-vdQ",
                "subject": "Cras tellus tortor, malesuada ac neque mollis, accumsan dignissim lectus",
                "body": "<p>Cras tellus tortor, malesuada ac neque mollis, accumsan dignissim lectus. Nunc bibendum, sem aliquet bibendum tincidunt, nisl nibh placerat nunc, sit amet sagittis nisl nisi at velit. Pellentesque ligula metus, pellentesque id tempor vel, congue at libero. Donec sapien tellus, pharetra at lorem commodo, iaculis blandit tellus. Mauris tempus odio nec blandit tempus. Ut vel pharetra felis, quis luctus velit. Sed aliquam elementum magna, vel pellentesque sem. Sed at finibus nibh. Donec eu est sapien. Etiam sit amet vehicula velit, a euismod urna. Donec nec accumsan ante.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 14:00:26",
                "scheduled_at": null,
                "created_at": "2024-06-17 14:00:22",
                "files": []
            }
        },
        {
            "uid": "J2VZHZv_eU",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 14:01:49",
            "email": {
                "uid": "FQ7isDAtxp",
                "subject": "Aliquam ornare orci tellus, malesuada accumsan nisl suscipit sed",
                "body": "<p>Aliquam ornare orci tellus, malesuada accumsan nisl suscipit sed. Duis non tempor nulla. Praesent vel enim quis libero pellentesque aliquam a id ex. Etiam vitae augue vel sapien posuere congue a vitae ante. Morbi semper varius ipsum, ut finibus odio semper non. Aenean quis pulvinar orci. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Mauris eleifend leo velit, et mattis augue ultrices a.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 14:01:50",
                "scheduled_at": null,
                "created_at": "2024-06-17 14:01:45",
                "files": []
            }
        },
        {
            "uid": "WLdiekhc0U",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 14:00:02",
            "email": {
                "uid": "2PiOJO9x0v",
                "subject": "Praesent cursus, ante at lobortis aliquet, leo odio blandit risus, quis dictum augue elit ut risus",
                "body": "<p>Praesent cursus, ante at lobortis aliquet, leo odio blandit risus, quis dictum augue elit ut risus. Fusce vel arcu quis erat pulvinar feugiat ac sed ligula. Morbi aliquam, metus nec bibendum blandit, orci enim tristique dui, sodales euismod orci felis quis sapien. Donec finibus dapibus elit commodo varius. Suspendisse aliquam vehicula diam a aliquet. Maecenas sed augue in urna tincidunt interdum. Pellentesque et quam ac dui condimentum interdum. Curabitur ac scelerisque turpis, nec hendrerit ipsum. Suspendisse potenti. Cras consequat tincidunt malesuada. Pellentesque nec erat luctus, rhoncus nibh ut, malesuada dui. Aenean ut est nec neque porta eleifend. In ac varius nunc. Sed ornare eu erat ut ultricies. Nullam vestibulum in risus quis condimentum. Integer eu nisl et diam iaculis eleifend.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 14:00:02",
                "scheduled_at": null,
                "created_at": "2024-06-17 13:59:58",
                "files": []
            }
        },
        {
            "uid": "8a65wCxNsG",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 13:59:41",
            "email": {
                "uid": "DRNMV69ms6",
                "subject": "Integer luctus facilisis ante vulputate facilisis",
                "body": "<p>Integer luctus facilisis ante vulputate facilisis. Donec sed varius ligula, id ullamcorper elit. Nam ante nisl, pretium et tellus non, rhoncus rhoncus ante. Vivamus pretium porttitor justo. Sed ac diam et neque aliquet euismod quis ac mauris. Etiam sed dolor id quam egestas consequat. Nunc bibendum egestas erat et efficitur. Aenean sed molestie lacus. Curabitur sollicitudin dolor eu turpis convallis ultricies. In at rutrum orci. Proin est erat, blandit aliquet pellentesque id, malesuada quis purus. Proin vitae aliquet turpis, sollicitudin vestibulum tortor. Donec arcu sem, congue sit amet laoreet vitae, lacinia nec ante.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 13:59:41",
                "scheduled_at": null,
                "created_at": "2024-06-17 13:59:37",
                "files": []
            }
        },
        {
            "uid": "F-G2162n6_",
            "favorite": false,
            "read_at": null,
            "deleted_at": null,
            "created_at": "2024-06-17 13:59:21",
            "email": {
                "uid": "nWIiRW-HZG",
                "subject": "Lorem ipsum dolor sit amet",
                "body": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et orci sed nibh pretium malesuada. Donec aliquam augue at lorem porta vulputate. Ut nec magna sed nulla egestas pretium vel vel urna. Maecenas eu diam semper enim sagittis scelerisque. Phasellus ut neque nibh. Duis luctus magna eu tellus consequat tincidunt. Fusce in lorem lacus. Morbi vitae orci metus. Integer a dolor fringilla, imperdiet quam sed, mollis ex. Duis non elit ultricies nunc mollis efficitur tempus ut ligula. Integer quis magna ut lectus dignissim tincidunt sit amet nec sem. Morbi ut feugiat ipsum, malesuada posuere odio.<\/p>",
                "draft": false,
                "members": [
                    "YUfvK0XLpW"
                ],
                "groups": [],
                "sent_at": "2024-06-17 13:59:21",
                "scheduled_at": null,
                "created_at": "2024-06-17 13:59:17",
                "files": []
            }
        }
    ]

    const resourcesDemoData = [
        {
            "uid": "d9iOGJDbql",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "FdJ6cF67lA",
                "title": "Lorem Ipsum Title 1",
                "details": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-14 19:26:49"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-14 19:26:49",
            "deleted_at": null
        },
        {
            "uid": "p7iMPLTgwy",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "HiJ7bH98kL",
                "title": "Lorem Ipsum Title 2",
                "details": "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-15 10:00:00"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-15 10:00:00",
            "deleted_at": null
        },
        {
            "uid": "m3kPLHdkl9",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "BdJ8dG99mP",
                "title": "Lorem Ipsum Title 3",
                "details": "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-16 14:30:00"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-16 14:30:00",
            "deleted_at": null
        },
        {
            "uid": "a8jLHTrnp2",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "CdJ9dH90oQ",
                "title": "Lorem Ipsum Title 4",
                "details": "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-17 09:45:00"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-17 09:45:00",
            "deleted_at": null
        },
        {
            "uid": "r5kPLMqrs8",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "EdJ1eI11pR",
                "title": "Lorem Ipsum Title 5",
                "details": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-18 12:00:00"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-18 12:00:00",
            "deleted_at": null
        },
        {
            "uid": "q9lNMRuwy7",
            "organization": "demo",
            "resourceable_type": "page",
            "resourceable": {
                "uid": "GfJ2gJ12sT",
                "title": "Lorem Ipsum Title 6",
                "details": "Nulla facilisi. Cras non velit nec nisi vulputate nonummy.",
                "resource_category": {
                    "uid": "Ykwk_GVxAs",
                    "name": "Legal"
                },
                "thumbnail": null,
                "cover": null,
                "created_at": "2024-06-19 16:20:00"
            },
            "draft": false,
            "tags": [],
            "groups": [],
            "members": [
                "YUfvK0XLpW"
            ],
            "totalLikes": 0,
            "isLiked": false,
            "global": false,
            "created_at": "2024-06-19 16:20:00",
            "deleted_at": null
        }
    ];

    const demoChatData = [
        {
            "conversationId": "borrodpag_36_user_yufvk0xlpw38",
            "conversationType": "user",
            "unreadMessageCount": 0,
            "createdAt": 1717674658,
            "updatedAt": 1717674658,
            "lastMessage": {
                "id": "3606",
                "conversationId": "borrodpag_36_user_yufvk0xlpw38",
                "sender": "borrodpag_36",
                "receiverType": "user",
                "receiver": "yufvk0xlpw38",
                "category": "message",
                "type": "text",
                "data": {
                    "text": "Lorem ipsum dolor sit amet.",
                    "entities": {
                        "sender": {
                            "entity": {
                                "uid": "borrodpag_36",
                                "name": "John Doe",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717587746
                            },
                            "entityType": "user"
                        },
                        "receiver": {
                            "entity": {
                                "uid": "yufvk0xlpw38",
                                "name": "Jane Smith",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717585548,
                                "conversationId": "borrodpag_36_user_yufvk0xlpw38"
                            },
                            "entityType": "user"
                        }
                    },
                    "metadata": {
                        "@injected": {
                            "extensions": {
                                "link-preview": {
                                    "links": []
                                }
                            }
                        }
                    },
                    "resource": "WEB-3_0_12-493afc3d-a643-4293-9a69-2ca609aaff0c-1717673262457"
                },
                "sentAt": 1717674658,
                "deliveredAt": 1718194059,
                "readAt": 1718194059,
                "updatedAt": 1718194059
            },
            "lastReadMessageId": "3606",
            "conversationWith": {
                "uid": "borrodpag_36",
                "name": "John Doe",
                "metadata": {
                    "organizations": [
                        "demo"
                    ]
                },
                "status": "offline",
                "role": "default",
                "lastActiveAt": 1718636369,
                "conversationId": "borrodpag_36_user_yufvk0xlpw38"
            }
        },
        {
            "conversationId": "user_alex123_user_jane456",
            "conversationType": "user",
            "unreadMessageCount": 3,
            "createdAt": 1717674759,
            "updatedAt": 1717674759,
            "lastMessage": {
                "id": "3607",
                "conversationId": "user_alex123_user_jane456",
                "sender": "alex123",
                "receiverType": "user",
                "receiver": "jane456",
                "category": "message",
                "type": "text",
                "data": {
                    "text": "Consectetur adipiscing elit.",
                    "entities": {
                        "sender": {
                            "entity": {
                                "uid": "alex123",
                                "name": "Agent Smith",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717587750
                            },
                            "entityType": "user"
                        },
                        "receiver": {
                            "entity": {
                                "uid": "jane456",
                                "name": "Jane Doe",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717585552,
                                "conversationId": "user_alex123_user_jane456"
                            },
                            "entityType": "user"
                        }
                    },
                    "metadata": {
                        "@injected": {
                            "extensions": {
                                "link-preview": {
                                    "links": []
                                }
                            }
                        }
                    },
                    "resource": "WEB-3_0_12-493afc3d-a643-4293-9a69-2ca609aaff0c-1717673262460"
                },
                "sentAt": 1717674759,
                "deliveredAt": 1718194060,
                "readAt": 1718194060,
                "updatedAt": 1718194060
            },
            "lastReadMessageId": "3607",
            "conversationWith": {
                "uid": "alex123",
                "name": "Alex Johnson",
                "metadata": {
                    "organizations": [
                        "demo"
                    ]
                },
                "status": "offline",
                "role": "default",
                "lastActiveAt": 1718636370,
                "conversationId": "user_alex123_user_jane456"
            }
        },
        {
            "conversationId": "user_mike789_user_lisa321",
            "conversationType": "user",
            "unreadMessageCount": 1,
            "createdAt": 1717674850,
            "updatedAt": 1717674850,
            "lastMessage": {
                "id": "3608",
                "conversationId": "user_mike789_user_lisa321",
                "sender": "mike789",
                "receiverType": "user",
                "receiver": "lisa321",
                "category": "message",
                "type": "text",
                "data": {
                    "text": "Sed do eiusmod tempor incididunt ut labore.",
                    "entities": {
                        "sender": {
                            "entity": {
                                "uid": "mike789",
                                "name": "Luke Skywalker",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717587760
                            },
                            "entityType": "user"
                        },
                        "receiver": {
                            "entity": {
                                "uid": "lisa321",
                                "name": "Lisa White",
                                "role": "default",
                                "status": "offline",
                                "metadata": {
                                    "organizations": [
                                        "demo"
                                    ]
                                },
                                "lastActiveAt": 1717585562,
                                "conversationId": "user_mike789_user_lisa321"
                            },
                            "entityType": "user"
                        }
                    },
                    "metadata": {
                        "@injected": {
                            "extensions": {
                                "link-preview": {
                                    "links": []
                                }
                            }
                        }
                    },
                    "resource": "WEB-3_0_12-493afc3d-a643-4293-9a69-2ca609aaff0c-1717673262470"
                },
                "sentAt": 1717674850,
                "deliveredAt": 1718194061,
                "readAt": 1718194061,
                "updatedAt": 1718194061
            },
            "lastReadMessageId": "3608",
            "conversationWith": {
                "uid": "mike789",
                "name": "Mike Brown",
                "metadata": {
                    "organizations": [
                        "demo"
                    ]
                },
                "status": "offline",
                "role": "default",
                "lastActiveAt": 1718636371,
                "conversationId": "user_mike789_user_lisa321"
            }
        },
    ]

    return {
        newsDemoData,
        inboxDemoData,
        resourcesDemoData,
        demoChatData
    }
}