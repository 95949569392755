<template>
  <component
      ref="auxComponentRef"
      :is="component.component"
      :phoneNumber="phoneNumber"
      v-bind="{...component.props}"
      v-on="{...component.events}"
  >
  </component>
</template>

<script setup>
import OtpCheck from "@/components/modals/partials/OtpCheck.vue";
import SuccessVerification from "@/components/modals/partials/SuccessVerification.vue";
import PhoneNumberAlreadyUsed from "@/components/modals/partials/PhoneNumberAlreadyUsed.vue";
import {useVerifyPhoneNumber} from "@/stores/VerifyPhoneNumberStore";
import {useAuth} from "@/stores/AuthStore";
import {defineProps, computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import router from "@/router";
import {getFirebaseTokenOrRequestPermission} from "@/plugins/firebase.js";
import {cookieStorage} from "@/pinia/cookieStorage";
import {useNotifications} from "@/composables/useNotifications";
import {useSignUp} from "@/stores/SignUpStore";
import {usePreloader} from "@/composables/usePreloader";

const {showPreloader, hidePreloader} = usePreloader();

const { getMemberInfo, member, init } = useAuth();
const {storeFcmToken} = useNotifications();

const signUpStore = useSignUp();
const verifyStore = useVerifyPhoneNumber();
const {token, user, notificationToken, organization} = storeToRefs(useAuth());

const route = useRoute();

const otpNumber = ref('');

const {error} = storeToRefs(verifyStore);

const auxComponentRef = ref(null);

const props = defineProps({
    show: {
        required: true,
        type: Boolean,
    },
    phoneNumber: {
        required: true,
        type: String
    },
    uid: {
        required: true,
        type: String
    },
    type: {
        required: false,
        type: String,
        default: 'users'
    }
});

onMounted(() => {
    verifyStore.setType(props.type);
});

const emit = defineEmits([
    'canceled',
    'finished'
]);

const phoneNumberAlreadyUsedComponent = {
    component: PhoneNumberAlreadyUsed,
    events: {},
};

const phoneNumberSuccessfullyVerifiedComponent = {
    component: SuccessVerification,
    events: {
        finished: async () => {
            emit('finished');

            showPreloader();

            if(signUpStore.user && signUpStore.user.uid && signUpStore.user.uid === user.value.uid) {
                try {
                    await signUpStore.createMembership(organization.value.uid);
                    await init(organization.value.slug);
                    signUpStore.reset();
                    await router.push('onboarding');
                } catch(error) {
                    console.log(error);
                } finally{
                    verifyStore.reset();
                }
                hidePreloader()
                return;
            }

            try {
                await getMemberInfo(organization.value.slug, user.value.uid)
                await getFirebaseTokenOrRequestPermission().then(async (token) => {
                    const fcmToken = await storeFcmToken(organization.value.slug, member.value.uid, token);
                    notificationToken.value = fcmToken;
                });

            } catch (e) {
                console.log(e);
            }

            hidePreloader()

            await router.push({path: "/onboarding"});
        }
    },
};

const verifyPhoneNumberComponent = {
    component: OtpCheck,
    props: {
        error: () => {return error.value}
    },
    events: {
        canceled: () => {
            error.value = ''
            emit('canceled')
        },
        resend: () => {
            verifyStore.sendOTP(props.uid, props.phoneNumber, route.query.token ?? null)
        },
        clearErrors: () => {
            error.value = '';
        },
        confirm: (otp) => {
            otpNumber.value = otp;
            verifyStore.checkOTP(props.uid, props.phoneNumber, otp, route.query.token ?? null).then(async (response) => {
                if (response.data.token && response.data.user) {
                    token.value = response.data.token;
                    user.value = response.data.user;

                    if(response.data.invitation) {
                        cookieStorage.setItem('user-uid', user.value.uid);
                    }
                }
            }).finally(() => {
                auxComponentRef.value.resetLoader();
            });
        }
    },
};

const component = computed(() => {
    if (verifyStore.used) {
        return phoneNumberAlreadyUsedComponent;
    }

    return verifyStore.verified ? phoneNumberSuccessfullyVerifiedComponent : verifyPhoneNumberComponent;
});

</script>
