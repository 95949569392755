<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="inline-flex gap-1 justify-center w-full bg-white text-body-6 font-normal text-default-1 items-center">
                <Avatar v-if="member" :firstName="member.first_name " :lastName="member.last_name" :avatar="member.avatar" />
                <p class="text-body-6 text-default-1 font-normal ml-2">{{ userFullName }}</p>
                <img src="@/assets/icons/chevron-down.svg" class="h-4.5 w-2.5" alt="open"/>
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none cursor-pointer">
                <div class="py-1">
                    <MenuItem v-if="isAdmin" v-slot="{ active }">
                        <a href="https://support.nepservices.com/ticket_form?_gl=1*hxrhj1*_ga*MTMwMjc3OTI1Ni4xNjc4MzkxNjY2*_ga_B0FV6DV3D4*MTY5MjYzMDcxNi4xMy4xLjE2OTI2MzIwMjcuMC4wLjA"
                           target="_blank"
                           :class="[active ? '' : 'text-gray-700', 'group flex items-center py-2 pr-5 pl-4 text-body-6 font-medium text-default-1 hover:bg-background-3']">
                            <img src="@/assets/icons/headphone.svg" class="h-5 w-5 mr-2.5" alt="support"/>
                            Contact NEP Support
                        </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <a @click="router.push('/profile')"
                           :class="[active ? '' : 'text-gray-700', 'group flex items-center py-2 pr-5 pl-4 text-body-6 font-medium text-default-1 hover:bg-background-3']">
                            <img src="@/assets/icons/settings.svg" class="h-5 w-5 mr-2.5" alt="settings"/>
                            Profile settings
                        </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <a @click.prevent="handleLogout"
                           href="#"
                           :class="[active ? '' : 'text-gray-700', 'group flex items-center py-2 pr-5 pl-4 text-body-6 font-medium text-default-1 hover:bg-background-3']">
                            <img src="@/assets/icons/logout.svg" class="h-5 w-5 mr-2.5" alt="logout"/>
                            <p> Log out </p>
                        </a>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
<script setup>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {useAuth} from "@/stores/AuthStore";
import {useOnboarding} from "@/stores/OnboardingStore";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {useCookies} from "@vueuse/integrations/useCookies";
import {useNotifications} from "@/composables/useNotifications";
import Avatar from "@/components/custom/Avatar.vue";
import {CometChat} from "@cometchat-pro/chat";
import {usePreloader} from "@/composables/usePreloader";

const {showPreloader, hidePreloader} = usePreloader();

const {token, user, member, fields, userFullName, organization, notificationToken} = storeToRefs(useAuth());
const {currentStep} = storeToRefs(useOnboarding());
const {deleteFcmToken} = useNotifications();
const router = useRouter();

const {isAdmin} = useAuth();

const cookie = useCookies(null, {doNotParse: true});

async function logoutCometChat() {
    const loggedInUser = await CometChat.getLoggedinUser();

    if (loggedInUser) {
        try {
            await CometChat.logout();
        } catch (error) {
            console.error("CometChat logout failed", error);
        }
    } else {
        console.log("No user is logged in CometChat");
    }
}

const handleLogout = async function () {
    showPreloader();

    if (notificationToken.value !== null) {
        await deleteFcmToken(organization.value.slug, member.value.uid, notificationToken.value.uid)
            .then(() => {
                notificationToken.value = null;
            }).catch(err => {
                if (err.status === 404) {
                    console.log('notification token not found');
                }
            });
    }

    token.value = null;
    user.value = null;
    fields.value = [];
    currentStep.value = 0;

    cookie.remove('user-uid', {
        path: '/',
        domain: window.appConfig.DOMAIN
    });
    await logoutCometChat();

    await router.push('/login');

    member.value = null;

    hidePreloader();
}
</script>
